import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const CPlaidErrorModal = ({open, content, handleClose, handleReset}) => {

  return (
    <Dialog
      sx={{
        borderRadius: "16x",
      }}
      minWidth={"xs"}
      fullWidth
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
        <div style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItemsL: "flex-end",
        }}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          top: { xs: 4, md: 8 },
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
        </div>
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "18px !important",
        }}
        id="alert-dialog-title"
      >
        <div>
          <img
            style={{ width: "40px", height: "40px" }}
            src="https://growcredit-assets.s3.us-west-2.amazonaws.com/error_icon.png"
            alt="Error icon"
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            textAlign: "center",
          }}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleReset}>Retry</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CPlaidErrorModal;
