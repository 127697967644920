import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { sendApiRequest } from "lib/axios";
import {
  registrationCheck,
  landingPageCaptureData,
  landingPageSaveEmail,
  customerApprovalStatus,
  textAppLink,
  legalAgreementFees,
  legalAgreementLoan,
  legalAgreementEsign,
  verifyCaptcha,
} from "../slice/commonSlice";
import { AppActions } from "../types";

export function* registrationCheckSaga() {
  const response = yield call(
    sendApiRequest,
    null,
    "registration/allowed",
    "get"
  );
  yield put(registrationCheck(response));
}

export function* landingPageCaptureDataSaga(data) {
  const response = yield call(
    sendApiRequest,
    data.payload,
    "landing_page/capture_data",
    "post"
  );
  yield put(landingPageCaptureData(response));
}

export function* landingPageSaveEmailSaga(data) {
  const response = yield call(
    sendApiRequest,
    data.payload,
    "landing_page/save_email",
    "post"
  );
  yield put(landingPageSaveEmail(response));
}
export function* landingPageSavePhoneNoSaga(data) {
  const response = yield call(
    sendApiRequest,
    data.payload,
    "landing_page/save_phone_number",
    "post"
  );
  yield put(landingPageSaveEmail(response));
}
export function* customerApprovalStatusSaga() {
  const response = yield call(
    sendApiRequest,
    null,
    "post_register/status",
    "get"
  );
  yield put(customerApprovalStatus(response));
}

export function* textAppLinkSaga() {
  const response = yield call(
    sendApiRequest,
    null,
    "onboarding/send_sms",
    "post"
  );
  yield put(textAppLink(response));
}
export function* sendEventSaga(data) {
  yield call(
    sendApiRequest,
    data ? data.payload : null,
    "onboarding/send_event",
    "post"
  );
}

export function* legalAgreementFeesSaga() {
  const payload = {"x-document": true};
  const response = yield call(
    sendApiRequest,
    payload,
    "legal/program_fees",
    "get"
  );
  yield put(legalAgreementFees(response));
}

export function* legalAgreementLoanSaga() {
  const payload = {"x-document": true};
  const response = yield call(sendApiRequest, payload, "legal/loan", "get");
  yield put(legalAgreementLoan(response));
}

export function* legalAgreementEsignSaga() {
  const payload = {"x-document": true};
  const response = yield call(sendApiRequest, payload, "legal/esign", "get");
  yield put(legalAgreementEsign(response));
}

export function* textLandingPageAppLinkSaga(data) {
  const response = yield call(
    sendApiRequest,
    data.payload,
    "mobile_landing_page/send_sms",
    "post"
  );
  yield put(textAppLink(response));
}

export function* verifyCaptch(data) {
  const response = yield call(
    sendApiRequest,
    data.payload,
    "login/verify/captcha",
    "post"
  );

  yield put(verifyCaptcha(response));
}

export function* watchAppCommonAsync() {
  yield takeEvery(AppActions.REGISTRATION_CHECK, registrationCheckSaga);
  yield takeEvery(AppActions.LANDING_PAGE_DATA, landingPageCaptureDataSaga);
  yield takeEvery(AppActions.LANDING_PAGE_EMAIL, landingPageSaveEmailSaga);
  yield takeEvery(AppActions.LANDING_PAGE_PHONE_NO, landingPageSavePhoneNoSaga);
  yield takeLatest(
    AppActions.CUSTOMER_APPROVAL_STATUS,
    customerApprovalStatusSaga
  );
  yield takeEvery(AppActions.TEXT_APPS_LINK, textAppLinkSaga);
  yield takeEvery(AppActions.SEND_EVENT, sendEventSaga);
  yield takeEvery(AppActions.LEGAL_AGREEMENT_FEES, legalAgreementFeesSaga);
  yield takeEvery(AppActions.LEGAL_AGREEMENT_LOAN, legalAgreementLoanSaga);
  yield takeEvery(AppActions.LEGAL_AGREEMENT_ESIGN, legalAgreementEsignSaga);
  yield takeEvery(
    AppActions.TEXT_LANDING_PAGE_APPS_LINK,
    textLandingPageAppLinkSaga
  );
  yield takeEvery(AppActions.VERIFY_GOOGLE_CAPTCHA, verifyCaptch);
}
