import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { AnalyticsBrowser } from "@segment/analytics-next";
import AppBar from "./components/AppBar";
import CErrorModal from "./components/custom/CErrorModal";
import routes from "./routes";
import useDynamicTheme from "./hooks/useDynamicTheme";
import defaultTheme, { GlobalStyled } from "./theme";
import { getThemeConfig, ToastOptions } from "./constants/Colors";
import CustomLoader from "components/custom/CustomLoader";
export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_SEGMENT_KEY,
});

function App() {
  const [theme, globalStyleConfig, setTheme] = useDynamicTheme(null);
  const content = useRoutes(routes);
  const { configurations } = useSelector((state) => state.authentication);
  const { isCommonLoading } = useSelector((state) => state.commonReducer);
  useEffect(() => {
    if (configurations) {
      const activeTheme = getThemeConfig(configurations);
      setTheme(activeTheme);
    }
  }, [configurations, setTheme]);

  const { customerStatus } = useSelector((state) => state.commonReducer);

  if (
    process.env.REACT_APP_ENV === "production" ||
    process.env.REACT_APP_ENV === "sandbox"
  ) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: process.env.REACT_APP_DATADOG_RUM_APP_NAME,
      version: process.env.REACT_APP_VESION,
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
      silentMultipleInit: true,
      forwardConsoleLogs: false,
    });
    datadogRum.setGlobalContextProperty("UserInfo", {
      CustomerEmail: customerStatus && customerStatus.account.email,
      CustomerId: customerStatus && customerStatus.account.customer_guid,
    });
    datadogRum.setUserProperty(
      "userEmail",
      customerStatus && customerStatus.account.email
    );
    datadogRum.startSessionReplayRecording();
  }
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    silentMultipleInit: true,
  });
  datadogLogs.setUser({
    customerId: customerStatus && customerStatus.account.customer_id,
    email: customerStatus && customerStatus.account.email,
  });
  datadogLogs.setUserProperty("type", "customer");

  return (
    <React.Fragment>
      <CssBaseline />
      {theme && <GlobalStyled config={globalStyleConfig} />}
      <ThemeProvider theme={theme ? theme : defaultTheme}>
        <AppBar />
        {theme ? content : null}
        <CErrorModal />
        <Toaster gutter={8} toastOptions={ToastOptions} />
        {isCommonLoading ? <CustomLoader /> : null}
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
