import { createSlice } from "@reduxjs/toolkit";
import { AccountCreateMessages } from "constants/Messages";

const slice = createSlice({
  name: "linkBankAccount",
  initialState: {
    plaidTokenSuccess: false,
    plaidToken: "",
    plaidTokenError: false,
    plaidTokenErrorMsg: "",
    addBankSuccess: false,
    addBankError: false,
    addBankErrorMsg: "",
    plaidUpdateToken: "",
    plaidUpdateTokenSuccess: "",
    plaidUpdateTokenError: "",
    plaidUpdateTokenErrorMsg: "",
  },
  reducers: {
    getPlaidToken: (state, action) => {
      let error = action.payload.error;
      if (!error) {
        state.plaidToken = action.payload.result.data.link_token;
        state.plaidTokenSuccess = true;
      } else {
        state.plaidTokenError = true;
        state.plaidTokenErrorMsg = action.payload.result.message
          ? action.payload.result.message
          : AccountCreateMessages.ERROR_PLAID_TOKEN;
      }

      return state;
    },
    addBank: (state, action) => {
      let error = action.payload.error;
      if (!error) {
        state.addBankSuccess = true;
      } else {
        state.addBankError = true;
        state.addBankErrorMsg = action.payload.result.error.message
          ? action.payload.result.error.message
          : AccountCreateMessages.ERROR_PLAID_ADD_BANK;
      }

      return state;
    },
    getPlaidUpdateToken: (state, action) => {
      let error = action.payload.error;
      if (!error) {
        state.plaidUpdateToken = action.payload.result.data.link_token;
        state.plaidUpdateTokenSuccess = true;
      } else {
        state.plaidUpdateTokenError = true;
        state.plaidUpdateTokenErrorMsg = action.payload.result.message
          ? action.payload.result.message
          : AccountCreateMessages.ERROR_PLAID_TOKEN;
      }

      return state;
    },
    resetPlaidToken: (state) => {
      state.plaidTokenSuccess = false;
    },

    resetPlaidTokenError: (state) => {
      state.plaidTokenError = false;
      state.plaidTokenErrorMsg = "";
    },

    reset: (state) => {
      state.plaidTokenSuccess = false;
      state.plaidToken = "";
      state.plaidTokenError = false;
      state.plaidTokenErrorMsg = "";
      state.addBankSuccess = false;
      state.addBankError = false;
      state.addBankErrorMsg = "";

      return state;
    },
  },
});

export const {
  getPlaidToken,
  addBank,
  reset,
  getPlaidUpdateToken,
  resetPlaidToken,
  resetPlaidTokenError,
} = slice.actions;

export default slice.reducer;
