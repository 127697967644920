import React from "react";
import { Grid } from "@mui/material";
import CTypography from "components/custom/CTypography";
import { CenterContainer } from "common/styles";

const RegistrationClosed = () => {
  return (
    <CenterContainer maxWidth={"md"}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign={"center"}>
          <CTypography
            sx={{
              lineHeight: 1,
              fontSize: "1.5rem",
              fontWeight: 700,
              color: "#28335B",
              pb: 3,
            }}
          >
            Oops!
          </CTypography>
          <CTypography
            sx={{
              fontSize: "1.3rem",
              fontWeight: 400,
              color: "#28335bd9",
              mt: 3,
            }}
          >
            We are not taking new applications at this time. It will be back
            soon.
          </CTypography>
          <CTypography
            sx={{
              fontSize: "1.2rem",
              fontWeight: 400,
              color: "#28335bd9",
              mt: 2,
              mb: 2,
            }}
          >
            Please contact Grow Credit team for further information.
          </CTypography>
        </Grid>
      </Grid>
    </CenterContainer>
  );
};

export default RegistrationClosed;
