import {
  checkoutViaAch,
  checkoutViaCard,
  addDebitCard,
  defaultPaymentMethod,
} from "../slice/checkout";
import { sendApiRequest } from "../../lib/axios";
import { Checkout } from "../types";

import { put, takeEvery, call } from "redux-saga/effects";

export function* checkoutViaAchSaga() {
  const result = yield call(
    sendApiRequest,
    null,
    "onboarding/checkout/ach",
    "post"
  );
  yield put(checkoutViaAch(result));
}
export function* checkoutViaCardSaga(data) {
  const result = yield call(
    sendApiRequest,
    data.payload,
    "onboarding/checkout/debit",
    "post"
  );
  yield put(checkoutViaCard(result));
}
export function* addDebitCardSaga(data) {
  const result = yield call(
    sendApiRequest,
    data.payload,
    "payment/card/save",
    "post"
  );
  yield put(addDebitCard(result));
}
export function* defaultPaymentMethodSaga(data) {
  const result = yield call(
    sendApiRequest,
    data.payload,
    "post_register/save_auto_payment_opt_in",
    "post"
  );
  yield put(defaultPaymentMethod(result));
}
export function* watchCheckoutAsync() {
  yield takeEvery(Checkout.CHECKOUT_VIA_ACH, checkoutViaAchSaga);
  yield takeEvery(Checkout.CHECKOUT_VIA_CARD, checkoutViaCardSaga);
  yield takeEvery(Checkout.ADD_PAYMENT_METHOD, addDebitCardSaga);
  yield takeEvery(
    Checkout.SET_DEFAULT_PAYMENT_METHOD,
    defaultPaymentMethodSaga
  );
}
