import { call, put, takeEvery } from "redux-saga/effects";
import { sendApiRequest } from "lib/axios";
import {
  offeredPlans,
  choosePlan,
  offeredLoan,
  offeredLoanDOM,
  acceptLoan,
  acceptEsign,
} from "../slice/membershipSlice";
import { MembershipActions } from "../types";

export function* offeredPlansSaga(data) {
  const result = yield call(
    sendApiRequest,
    null,
    "post_register/plan/offer",
    "get"
  );
  yield put(offeredPlans(result));
}

export function* choosePlanSaga(data) {
  const result = yield call(
    sendApiRequest,
    data.payload,
    "post_register/plan/choose",
    "post"
  );
  yield put(choosePlan(result));
}

export function* offeredLoanSaga(data) {
  const result = yield call(
    sendApiRequest,
    null,
    "post_register/loan/offer",
    "get"
  );
  yield put(offeredLoan(result));
}

export function* offeredLoanDOMSaga(data) {
  const result = yield call(
    sendApiRequest,
    null,
    "post_register/loan/get_payment_dom",
    "get"
  );
  yield put(offeredLoanDOM(result));
}

export function* acceptLoanSaga(data) {
  const result = yield call(
    sendApiRequest,
    data.payload,
    "post_register/loan/accept",
    "post"
  );
  yield put(acceptLoan(result));
}

export function* acceptEsignSaga(data) {
  const result = yield call(
    sendApiRequest,
    null,
    "post_register/esign/accept",
    "post"
  );
  yield put(acceptEsign(result));
}

export function* watchMembershipAsync() {
  yield takeEvery(MembershipActions.OFFERED_PLANS, offeredPlansSaga);
  yield takeEvery(MembershipActions.CHOOSE_PLAN, choosePlanSaga);
  yield takeEvery(MembershipActions.LOAN_AGREEMENT, offeredLoanSaga);
  yield takeEvery(MembershipActions.LOAN_AGREEMENT_DOM, offeredLoanDOMSaga);
  yield takeEvery(MembershipActions.ACCEPT_LOAN_AGREEMENT, acceptLoanSaga);
  yield takeEvery(MembershipActions.ACCEPT_ESIGN_AGREEMENT, acceptEsignSaga);
}
