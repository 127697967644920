import { createSlice } from "@reduxjs/toolkit";
import {setQueryParams} from "./commonSlice";
import { AccountCreateMessages, GlobalMessages } from "constants/Messages";

const initialState = {
  creatAccountSuccess: false,
  creatAccountSuccessMsg: "",
  createAccountError: false,
  createAccountErrorMsg: "",
  createAccountvalidationMessages: {},
  emailAlreadyExists: false,
  isLoggedIn: false,
  loginSuccess: false,
  loginError: false,
  loginErrorMessage: "",
  UserToken: "",
  registerAccountSuccess: false,
  // registerAccountSuccessMsg: "",
  registerAccountError: false,
  registerAccountErrorMsg: "",
  registerAccountvalidationMessages: {},
  personalAccountDetails: null,
  savePIISuccess: false,
  savePIIError: false,
  savePIIErrorMsg: "",
  customerGuid: null,
  fetchSubscriptionError: false,
  fetchSubscriptionErrorMsg: false,
  fetchSubscriptionSuccess: false,
  subsciptionList: [],
  queryParams: {}
};

const accountReg = createSlice({
  name: "accountRegistration",
  initialState,
  reducers: {
    createAccount: (state, action) => {
      let response = action.payload;
      if (response.error) {
        if (response.result) {
          if (response.result.data?.reason?.email === "Email already taken.") {
            state.emailAlreadyExists = true;
            return state;
          }
          state.createAccountError = true;
          state.createAccountvalidationMessages = response.result.data
            ? response.result.data.reason
            : {};
          state.createAccountErrorMsg =
            AccountCreateMessages.ERROR_CREATE_CUSTOMER_MESSAGE;
        } else {
          state.createAccountError = true;
          state.createAccountErrorMsg =
            AccountCreateMessages.ERROR_CREATE_CUSTOMER_MESSAGE;
        }
      } else {
        if (response.result.data.result) {
          state.creatAccountSuccess = true;
          state.customerGuid = response.result.data.customer_guid;
          state.creatAccountSuccessMsg =
            AccountCreateMessages.SUCCESS_CREATE_CUSTOMER_MESSAGE;
        }
      }
      return state;
    },
    reset: (state) => {
      state.creatAccountSuccess = false;
      state.creatAccountSuccessMsg = "";
      state.createAccountError = false;
      state.statuecustomerGuid = null;
      state.createAccountErrorMsg = "";
      state.createAccountvalidationMessages = {};
      state.loginSuccess = false;
      state.loginError = false;
      state.loginErrorMessage = "";
      state.registerAccountError = false;
      state.registerAccountErrorMsg = "";
      state.registerAccountSuccess = false;
      state.registerAccountvalidationMessages = {};
      if (
        sessionStorage.getItem("goals") ||
        sessionStorage.getItem("subscriptions")
      ) {
        sessionStorage.removeItem("goals");
        sessionStorage.removeItem("subscriptions");
      }

      return state;
    },
    loginAccount: (state, action) => {
      let error = action.payload.error;
      if (!error) {
        state.UserToken = action.payload.result.access_token;
        state.loginSuccess = true;
        sessionStorage.setItem(
          "user_token",
          action.payload.result.access_token
        );
        if(state.queryParams?.impact_click_id){
            window.top.postMessage(JSON.stringify({user_token: action.payload.result.access_token}), process.env.REACT_APP_TARGET_ORIGIN)
        }
        state.isLoggedIn = true;
      } else {
        state.loginError = true;
        state.loginErrorMessage = action.payload.result.message
          ? action.payload.result.message
          : AccountCreateMessages.ERROR_LOGIN_MESSAGE;
      }
      return state;
    },
    registerAccount: (state, action) => {
      let response = action.payload;
      if (response.error) {
        if (response.result) {
          state.registerAccountError = true;
          state.registerAccountvalidationMessages = response.result.data
            ? response.result.data.reason
            : {};
          state.registerAccountErrorMsg =
            AccountCreateMessages.ERROR_REGISTER_CUSTOMER_MESSAGE;
        } else {
          state.registerAccountError = true;
          state.registerAccountErrorMsg =
            AccountCreateMessages.ERROR_REGISTER_CUSTOMER_MESSAGE;
        }
      } else {
        if (response.result.data.result) {
          state.registerAccountSuccess = true;
          // state.creatAccountSuccessMsg =
          //   AccountregisterMessages.SUCCESS_REGISTER_CUSTOMER_MESSAGE;
        }
      }
      return state;
    },
    savePII: (state, action) => {
      let response = action.payload;
      if (response.error) {
        if (response.result) {
          state.savePIIError = true;
          state.registerAccountvalidationMessages = response.result.data
            ? response.result.data.reason
            : {};
          state.savePIIErrorMsg = AccountCreateMessages.SERVER_ERROR;
        } else {
          state.savePIIError = true;
          state.savePIIErrorMsg = AccountCreateMessages.SERVER_ERROR;
        }
      } else {
        if (response.result.data.result) {
          state.savePIISuccess = true;
        }
      }

      return state;
    },
    resetPIIState: (state) => {
      state.savePIISuccess = false;
      state.savePIIError = false;
      state.savePIIErrorMsg = "";

      return state;
    },
    accountDetails: (state, action) => {
      if (action.payload.result.data) {
        state.personalAccountDetails = action.payload.result.data.account;
      }
    },
    getSubscriptionListSaga: (state, action) => {
      let response = action.payload;
      if (response.error) {
        if (response.result) {
          state.fetchSubscriptionError = true;
          state.registerAccountvalidationMessages = response.result.data
            ? response.result.data.reason
            : {};
          state.fetchSubscriptionErrorMsg = GlobalMessages.SERVER_ERROR;
        } else {
          state.fetchSubscriptionError = true;
          state.fetchSubscriptionErrorMsg = GlobalMessages.SERVER_ERROR;
        }
      } else {
        if (response.result) {
          state.fetchSubscriptionSuccess = true;
          let subList = response.result;
          subList.forEach((object) => {
            object.selected = false;
          });
          subList.push({ id: 10000, name: "Not listed here", selected: false });
          state.subsciptionList = subList;
        }
      }

      return state;
    },
    resetAccountDetail: (state) => {
      state.personalAccountDetails = null;
      return state;
    },
    resetEmailAlreadyExists: (state) => {
      state.emailAlreadyExists = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setQueryParams, (state, action) => {
      state.queryParams = action.payload;
    })
  },
});

export const {
  createAccount,
  reset,
  loginAccount,
  registerAccount,
  accountDetails,
  savePII,
  resetPIIState,
  resetAccountDetail,
  resetEmailAlreadyExists,
  getSubscriptionListSaga,
} = accountReg.actions;

export default accountReg.reducer;
