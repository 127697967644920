import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";

export const CenterContainer = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "calc(100vh - 60px)",
}));
