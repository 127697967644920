import React, { createContext, useEffect } from "react";
import { setQueryParams } from "redux/slice/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppActions } from "redux/types";
const AppContext = createContext();

export const AppProvider = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const { landingPageGUID } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const value = parameters.get("token");

    if (!value) {
      const params = Object.fromEntries(parameters.entries());
      if (Object.keys(params).length > 0) {
        if (params._ref) {
          dispatch(setQueryParams({ _ref: params._ref }));
        }
        if (params.affiliate_link) {
          dispatch(setQueryParams({ affiliate_link: params.affiliate_link }));
        }
        if (params.affiliate_ptc) {
          dispatch(setQueryParams({ affiliate_ptc: params.affiliate_ptc }));
        }
        if (params.affiliate_id) {
          dispatch(setQueryParams({ affiliate_id: params.affiliate_id }));
        }
        if (params.affiliate_user_agent) {
          dispatch(
            setQueryParams({
              affiliate_user_agent: params.affiliate_user_agent,
            })
          );
        }
        if (params.customer_referral_reward_type) {
          dispatch(
            setQueryParams({
              customer_referral_reward_type:
                params.customer_referral_reward_type,
            })
          );
        }
        if (params.irclickid && params.ir_partnerId) {
          dispatch(
            setQueryParams({
              impact_click_id: params.irclickid,
              impact_partner_id: params.ir_partnerId,
            })
          );
          sessionStorage.setItem("irclickid", params.irclickid);
          sessionStorage.setItem("ir_partnerId", params.ir_partnerId);
        } else if (
          sessionStorage.getItem("irclickid") &&
          sessionStorage.getItem("ir_partnerId")
        ) {
          dispatch(
            setQueryParams({
              impact_click_id: params.irclickid,
              impact_partner_id: params.ir_partnerId,
            })
          );
        }
        if (params.onboarding_source) {
          dispatch(
            setQueryParams({ onboarding_source: params.onboarding_source })
          );
        }
        if (landingPageGUID === "") {
          dispatch({
            type: AppActions.LANDING_PAGE_DATA,
            payload: params,
          });
        }
      }
    } else {
      const params = Object.fromEntries(parameters.entries());
      if (Object.keys(params).length > 0) {
        if (params.onboarding_source) {
          dispatch(
            setQueryParams({ onboarding_source: params.onboarding_source })
          );
        }
      }
    }
  }, [dispatch, landingPageGUID]);

  return <AppContext.Provider>{children}</AppContext.Provider>;
};

export default AppContext;
