import React, {useCallback, useEffect} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import defaultTheme from "../theme";
import Toolbar from "@mui/material/Toolbar";
import {AuthActions} from "../redux/types";

export default function PrimarySearchAppBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLogo, setShowLogo] = React.useState(false);
  const [logoUrl, setLogoUrl] = React.useState("");
  const [activeTheme, setActiveTheme] = React.useState({ defaultTheme });
  const { configurations } = useSelector((state) => state.authentication);

  const logoutFromParent = useCallback((event) => {
      if(event.origin !== process.env.REACT_APP_TARGET_ORIGIN) return;
      // console.log('data received at child', event.data);
      const parsedData = JSON.parse(event.data);
      if(parsedData.logoutClicked){
        window.sessionStorage.clear();
        dispatch({ type: AuthActions.LOGOUT_ACTION });

        event.source.postMessage(JSON.stringify({childLogoutComplete:true}), event.origin);
      }
  },[dispatch]);

  useEffect(() => {
    window.addEventListener("message", logoutFromParent, false);
    return () => window.removeEventListener("message",logoutFromParent, false );
  }, [logoutFromParent]);

  useEffect(() => {
    if (configurations) {
      setShowLogo(configurations?.branding?.icons?.clientLogoDisplay);
      setLogoUrl(configurations?.branding?.icons?.clientLogoUrl);
      configurations?.branding?.theme?.active === "dark"
        ? setActiveTheme(configurations?.branding?.theme?.dark)
        : setActiveTheme(configurations?.branding?.theme?.light);
    }
  }, [configurations]);

  const {
    goalBack,
    bankAccountBack,
    currentBalanceBack,
    monthlyIncomeBack,
    agreementback,
    dobback,
    phoneBack,
    ssnBack,
  } = useSelector((state) => state.commonReducer);
  useEffect(() => {
    if (goalBack) navigate("/info");
    if (bankAccountBack) navigate("/goals");
    if (currentBalanceBack) navigate("/bankAccount");
    if (monthlyIncomeBack) navigate("/currentBalance");
    if (agreementback) navigate("/membership");
    if (dobback) navigate("/address");
    if (phoneBack) navigate("/dob");
    if (ssnBack) navigate("/phone");
  }, [
    goalBack,
    bankAccountBack,
    currentBalanceBack,
    monthlyIncomeBack,
    agreementback,
    dobback,
    phoneBack,
    ssnBack,
    navigate,
  ]);

  return (
    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
      <AppBar
        position="static"
        elevation={0}
        style={{
          background: activeTheme.body ? activeTheme.body : "#fff",
          height: "60px",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
          >
            <img
                alt="logo"
                src={logoUrl}
                height={33}
                style={{
                  visibility: logoUrl !== "" && showLogo ? "visible" : "hidden",
                }}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
