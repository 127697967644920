import { analytics } from "App";

export const segmentEvents = {
  //SelectSubscription Page
  PAGE_LOAD_SUBSCRIPTION: "page_load_select_subscription",
  ACTION_SUBSCRIPTIONS_SELECTED: "onboarding_subscriptions_submitted",
  //Goals Page
  PAGE_LOAD_CREDIT_GOALS: "page_load_credit_goals",
  ACTION_GOALS_SELECTED: "onboarding_about_goals_submitted",
  //UserRegistration Page
  PAGE_LOAD_ENTER_EMAIL_PASSWORD: "page_load_create_account",
  ACTION_SUBMIT_EMAIL: "onboarding_account_email_submitted",
  ACTION_SUBMIT_PASSWORD: "onboarding_account_password_submitted",
  ACTION_ACCOUNT_CREATED: "onboarding_account_created",
  //About Page
  PAGE_LOAD_ABOUT_YOURSELF: "page_load_about_yourself",
  ACTION_SUBMIT_NAME: "onboarding_about_name_submitted",
  //ConnectBank Page
  PAGE_LOAD_BANK_ACCOUNT: "page_load_bank_account",
  PAGE_LOAD_CONNECT_BANK: "page_load_bank_connect",
  ACTION_NO_BANK_ACCOUNT: "No_bank_account",
  ACTION_LINK_BANK_PRESSED: "onboarding_underwriting_link_bank",
  EVENT_BANK_SELECTED: "Bank Selected",
  //PlanSelection pages
  PAGE_LOAD_VIEW_PLANS: "page_load_view_plan",
  ACTION_VIEW_PLANS: "onboarding_plans_view_plans",
  PAGE_LOAD_CHOOSE_PLAN: "page_load_choose_plan",
  EVENT_SELECT_PLAN_CLICKED: "Select Plan Clicked",
  EVENT_PLAN_SELECTED: "Plan Selected",
  ACTION_CHOOSE_PLANS: "onboarding_plans_choose_plan",
  EVENT_TERMS_CREDIT_PROFILE_CONSENT: "Terms Credit Profile Consent",
  ACTION_BACK_TO_PLANS: "onboarding_plans_back_to_plans",
  PAGE_LOAD_SELECT_PLAN: "page_load_select_plan",
  // Confirmation Page
  PAGE_LOAD_VERIFY_IDENTITY: "page_load_verify_identity",
  ACTION_VERIFY_IDENTITY: "onboarding_pii_verify_submitted",
  //IAgree Page
  PAGE_LOAD_COLLECT_INFORMATION: "page_load_collect_information",
  ACTION_COLLECT_INFORMATION: "onboarding_pii_collect_information_agreed",
  //Address Page
  PAGE_LOAD_ENTER_ADDRESS: "page_load_enter_address",
  ACTION_SUBMIT_ADDRESS: "onboarding_pii_address_submitted",
  //DOB Page
  PAGE_LOAD_ENTER_DOB: "page_load_enter_date_of_birth",
  ACTION_SUBMIT_DOB: "onboarding_pii_dob_submitted",
  //PhoneNumber Page
  PAGE_LOAD_ENTER_PHONE_NUMBER: "page_load_enter_phone_number",
  ACTION_SUBMIT_PHONE: "onboarding_pii_phone_submitted",
  //SSN Page
  PAGE_LOAD_ENTER_SSN: "page_load_enter_ssn",
  ACTION_SUBMIT_SSN: "onboarding_pii_ssn_submitted",
  //Review Page
  PAGE_LOAD_PROCESSING: "page_load_processing",
  //ConfirmPlan Page
  PAGE_LOAD_CONFIRM_PLAN: "page_load_confirm_plan",
  ACTION_CONFIRMATION_PRESSED: "onboarding_confirmation",
  //MembershipAgreement page
  EVENT_ACCEPTED_LOAN_AGREEMENT: "Accepted loan agreement",
  ACTION_PLAN_AGREED: "onboarding_confirmation_agreed_to_plan",
  PAGE_LOAD_AUTOPAY_CONFIRMATION: "page_load_autopay_confirmation",
  ACTION_AUTOPAY_CONFIRMATION: "action_autopay_confirmation",
  AGREE_CONTINUE_BUTON_CLICKED: "agree_and_continue_button_clicked",
  //Checkout Page
  PAGE_LOAD_SELECT_PAYMENT: "page_load_select_payment_method",
  ACTION_PAYMENT_METHOD_PRESSED: "onboarding_confirmation_payment_submitted",
  PAGE_LOAD_CHECKOUT: "page_load_checkout",
  ACTION_PAYMENT_CHECKOUT: "onboarding_confirmation_checkout_completed",
  //Congratulations Page
  PAGE_LOAD_ONBOARDING_COMPLETED: "page_load_onboarding_completed",
  //TextLinkSection Page
  EVENT_TEXT_APP_LINK: "Text App Link",
  //Payment Method
  PAGE_LOAD_PAYMENT_METHOD: "page_load_payment_method",
  PRESS_SKIP_ON_PAYMENT_METHOD: "skipped_payment_method_screen",
  CONTINUE_WITH_CARD_DETAIL: "continue_button_clicked",
  ACTION_PAYMENT_METHOD_CONTINUE: "added_card_detail",

  // Payment Method Bank
  //Payment Method
  PAGE_LOAD_PAYMENT_METHOD_BANK: "page_load_payment_method_bank",
  CONTINUE_WITH_BANK_DETAIL: "continue_button_clicked",
  PRESS_LATER_PAYMENT_METHOD: "continue_without_auto_pay_opt_in",
  ACTION_PAYMENT_METHOD_CONTINUE_WITH_BANK:
    "added_bank_account_as_default_payment_method",
};
export const createAlias = (distinctId) => {
  analytics.alias(distinctId);
};
export const identify = (distinctId, traits = {}) => {
  analytics.identify(distinctId, traits);
};
export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform =
      window.navigator?.userAgentData?.platform || window.navigator.platform,
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = "web";

  if (iosPlatforms.indexOf(platform) !== -1) {
    os = "ios-web";
  } else if (
    userAgent.toLowerCase().includes("android") ||
    /Android/.test(userAgent) ||
    userAgent.toLowerCase().match(/android/)
  ) {
    os = "android-web";
  }

  return os !== undefined && os !== null ? os : "web";
}
export const sendSegmentEvent = (name, properties = {}) => {
  let source = sessionStorage.getItem("onboarding_source");

  if (source && source !== undefined) {
    if (source === "android-web" || source === "android") {
      properties["Onboarding Source"] = "android-widget";
    } else if (source === "ios-web" || source === "ios") {
      properties["Onboarding Source"] = "ios-app-web";
    } else {
      properties["Onboarding Source"] = getOS();
    }
  } else {
    properties["Onboarding Source"] = getOS();
  }
  properties["partner_name"] = sessionStorage.getItem("clientName");
  properties["partner_ref"] = sessionStorage.getItem("clientId");
  properties["external_ref"] = sessionStorage.getItem("uid");
  analytics.track(name, properties);
};
