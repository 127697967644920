import { all } from "redux-saga/effects";
import { watchAuthAsync } from "./authentication";
import { watchAccountRegAsync } from "./accountRegistration";
import { watchCheckoutAsync } from "./checkout";
import { watchAppCommonAsync } from "./appCommon";
import { watchLinkBankAsync } from "./linkBankAccount";
import { watchMembershipAsync } from "./membership";

export function* rootSaga() {
  yield all([
    watchAppCommonAsync(),
    watchAuthAsync(),
    watchAccountRegAsync(),
    watchLinkBankAsync(),
    watchMembershipAsync(),
    watchCheckoutAsync()
  ]);
}
