import { GlobalMessages, TextAppLinkMessages } from "constants/Messages";
import { createSlice } from "@reduxjs/toolkit";
import { identify } from "lib/segmentService";

const slice = createSlice({
  name: "commonReducer",
  initialState: {
    queryParams: {},
    isLoading: false,
    registrationAllowed: true,
    landingPageEmail: false,
    landingPageGUID: "",
    customerStatus: null,
    customerStatusError: null,
    textAppLinkSuccess: false,
    textAppLinkSuccessMsg: "",
    textAppLinkError: false,
    textAppLinkErrorMsg: "",
    legalAgreementFeesHtml: "",
    legalAgreementLoanHtml: "",
    legalAgreementEsignHtml: "",
    toastErrorMessage: "",
    showErrorModal: false,
    backPressed: false,
    goalBack: false,
    bankAccountBack: false,
    currentBalanceBack: false,
    monthlyIncomeBack: false,
    agreementback: false,
    dobback: false,
    phoneBack: false,
    ssnBack: false,
    verifyCaptchaSuccess: false,
    verifyCaptchaError: false,
    customerId: "",
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCommonLoading: (state, action) => {
      state.isCommonLoading = action.payload;
      return state;
    },
    showErrormessage: (state, action) => {
      state.showErrorModal = true;
      state.toastErrorMessage = action.payload;
    },
    removeErrorMessage: (state) => {
      state.showErrorModal = false;
      state.toastErrorMessage = "";
    },
    setQueryParams: (state, action) => {
      if (action.payload) {
        state.queryParams = { ...state.queryParams, ...action.payload };
        if (state.queryParams.onboarding_source) {
          window.sessionStorage.setItem(
            "onboarding_source",
            state.queryParams.onboarding_source
          );
        }
      }
      return state;
    },
    registrationCheck: (state, action) => {
      if (
        action.payload.result.data &&
        action.payload.result.data.can_register !== undefined
      ) {
        state.registrationAllowed = action.payload.result.data.can_register;
      }
      return state;
    },
    landingPageCaptureData: (state, action) => {
      if (action.payload.result.data) {
        state.landingPageGUID = action.payload.result.data;
      }
      return state;
    },
    landingPageSaveEmail: (state) => {
      state.landingPageEmail = true;
      return state;
    },
    customerApprovalStatus: (state, action) => {
      if (!action.payload.error) {
        if (action.payload.result.data) {
          let response = action.payload.result.data;
          state.customerStatus = response;
          sessionStorage.setItem(
            "re_application",
            state.customerStatus &&
              state.customerStatus.account.reapplication_required
          );
          sessionStorage.setItem(
            "bank_required",
            state.customerStatus && state.customerStatus.account.bank_required
          );
          state.customerId =
            state.customerStatus && state.customerStatus.account.customer_id;
          identify(
            state.customerStatus && state.customerStatus.account.customer_guid,
            {
              first_name:
                state.customerStatus && state.customerStatus.account.first_name,
              last_name:
                state.customerStatus && state.customerStatus.account.last_name,
              email: state.customerStatus && state.customerStatus.account.email,
              created: new Date(),
            }
          );
        }
      } else {
        state.customerStatusError = GlobalMessages.SERVER_ERROR;
      }
      return state;
    },
    resetApprovalStatus: (state) => {
      state.customerStatus = null;
      state.customerStatusError = null;
    },
    textAppLink: (state, action) => {
      if (!action.payload.error) {
        if (action.payload.result.data.result) {
          state.textAppLinkSuccess = action.payload.result.data.result;
          state.textAppLinkSuccessMsg =
            TextAppLinkMessages.TEXT_APP_LINK_SUCCESS;
        } else {
          state.textAppLinkError = true;
          state.textAppLinkErrorMsg = TextAppLinkMessages.TEXT_APP_LINK_ERROR;
        }
      } else {
        if (action.payload.result.data.reason) {
          state.textAppLinkError = true;
          state.textAppLinkErrorMsg = action.payload.result.data.reason;
          return;
        }
        state.textAppLinkError = true;
        state.textAppLinkErrorMsg = TextAppLinkMessages.TEXT_APP_LINK_ERROR;
      }

      return state;
    },
    textAppLinkReset: (state) => {
      state.textAppLinkError = false;
      state.textAppLinkErrorMsg = "";
      state.textAppLinkSuccess = false;
      state.textAppLinkSuccessMsg = "";
      return state;
    },
    legalAgreementFees: (state, action) => {
      if (action.payload.result) {
        state.legalAgreementFeesHtml = action.payload.result;
      }
      return state;
    },
    legalAgreementLoan: (state, action) => {
      if (action.payload.result) {
        state.legalAgreementLoanHtml = action.payload.result;
      }
      return state;
    },
    legalAgreementEsign: (state, action) => {
      if (action.payload.result) {
        state.legalAgreementEsignHtml = action.payload.result;
      }
      return state;
    },
    setBackPressed: (state, action) => {
      if (action.payload.path === "/goals") {
        state.bankAccountBack = false;
        state.goalBack = action.payload.value;
      }
      if (action.payload.path === "/bankAccount") {
        state.currentBalanceBack = false;
        state.bankAccountBack = action.payload.value;
      }
      if (action.payload.path === "/currentBalance") {
        state.monthlyIncomeBack = false;
        state.currentBalanceBack = action.payload.value;
      }
      if (action.payload.path === "/monthlyIncome") {
        state.monthlyIncomeBack = action.payload.value;
      }
      if (action.payload.path === "/agreement") {
        state.agreementback = action.payload.value;
      }
      if (action.payload.path === "/dob") {
        state.phoneBack = false;
        state.dobback = action.payload.value;
      }
      if (action.payload.path === "/phone") {
        state.ssnBack = false;
        state.phoneBack = action.payload.value;
      }
      if (action.payload.path === "/ssn") {
        state.ssnBack = action.payload.value;
      }
      return state;
    },
    verifyCaptcha: (state, action) => {
      if (!action.payload.error) {
        if (action.payload.result.data.result) {
          state.verifyCaptchaSuccess = true;
        } else {
          state.verifyCaptchaError = true;
        }
      } else {
        state.verifyCaptchaError = true;
      }
      return state;
    },
    resetBackPressed: (state) => {
      state.bankAccountBack = false;
      state.goalBack = false;
      state.currentBalanceBack = false;
      state.monthlyIncomeBack = false;
      state.agreementback = false;
      state.dobback = false;
      state.phoneBack = false;
      state.ssnBack = false;

      // if (action.payload === "/info") {
      //   state.bankAccountBack = false;
      //   state.goalBack = false;
      //   state.currentBalanceBack = false;
      //   state.monthlyIncomeBack = false;
      // }
      // if (action.payload === "/goals") {
      //   console.log("++++++slice---reset", action.payload);
      //   state.goalBack = false;
      //   state.bankAccountBack = false;
      //   // state.backCurrentBalance = ture;
      // }

      return state;
    },
  },
});

export const {
  setLoading,
  showErrormessage,
  removeErrorMessage,
  setQueryParams,
  registrationCheck,
  landingPageCaptureData,
  landingPageSaveEmail,
  customerApprovalStatus,
  resetApprovalStatus,
  textAppLink,
  textAppLinkReset,
  legalAgreementFees,
  legalAgreementLoan,
  legalAgreementEsign,
  setBackPressed,
  resetBackPressed,
  verifyCaptcha,
  setCommonLoading,
} = slice.actions;

export default slice.reducer;
