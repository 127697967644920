import { Common } from "constants/Common";
export const AccountCreateMessages = {
  SUCCESS_CREATE_CUSTOMER_MESSAGE: "Account has been created successfully.",
  ERROR_CREATE_CUSTOMER_MESSAGE: "Some error occurred, please try again",
  ERROR_LOGIN_MESSAGE: "The user credentials were incorrect.",
  ERROR_REGISTER_CUSTOMER_MESSAGE:
    "Some error occurred while registering your account, please try again",
  ERROR_PLAID_TOKEN: "Some error has occurred. Please try again!",
  ERROR_PLAID_ADD_BANK:
    "Some error occurred while registering bank, please try again",
};

export const CheckoutMessages = {
  CHECKOUT_SUCCESS: "Payment processed successfully",
  CHECKOUT_ERROR: "Some error occurred. Please try again later",
  FIRST_DECLINE_PAYMENT:
    "Your payment was declined, please check your account balance or try another card - 8001",
  SECOND_DECLINE_PAYMENT:
    "Your payment was not successful.  Please try another card - 8002",
  CHANGE_PLAN_TITLE: "Need to change your plan?",
  CHANGE_PLAN_DESC:
    "You can start over and pick a plan that works better for you.",
  CHANGE_PLAN_BTN: "Change My Plan",
  CHANGE_PLAN_BTN_CANCEL: "Finish Signing Up",
  ADD_DEBIT_CARD_ERROR: "Some error occurred. Please try again later",
  GENERAL_ERROR: "Something went wrong please try again or contact support.",
};

export const TextAppLinkMessages = {
  TEXT_APP_LINK_SUCCESS: "Text message has been sent successfully",
  TEXT_APP_LINK_ERROR: "Some error occurred while sending text",
};

export const MembershipMessages = {
  MEMBERSHIP_SCREEN_ERROR:
    "Something went wrong while loading membership plans.",
  AGREEMENT_SCREEN_ERROR: "Something went wrong while loading agreement.",
  CHOOSE_MEMBERSHIP_ERROR:
    "Something went wrong while selecting membership plan.",
  ACCEPT_AGREEMENT_ERROR: "Something went wrong while accepting agreement.",
  ACCEPT_ESIGN_AGREEMENT_ERROR:
    "Something went wrong while accepting E-sign consent.",
  EMPTY_PLAN_MESSAGE:
    "Something went wrong, please contact support for more information.(Code:EP100)",
};

export const GlobalMessages = {
  SERVER_ERROR: "Something went wrong please try again.",
  PLAID_RE_LINK_TITLE: "Your Bank Requires Re-Authentication",
  PLAID_RE_LINK_MESSAGE:
    "From time to time your bank requires you to reconnect to your account. Please follow the link below to allow us to continue to service your loan.",
  INCOMPATIBLE_BANK_TITLE: "Bank Account Not Supported.",
  INCOMPATIBLE_BANK_MESSAGE:
    "We are unable to connect to your bank account at this time. Please try again using a different account or press continue to use the Debit Card option.",
};
export const ConfigurationMessages = {
  FETCH_CONFIGURATION_ERROR_MESSAGE: `Token is invalid or expired. For more information please contact us at ${
    Common.CLIENT_EMAIL() ? Common.CLIENT_EMAIL() : "behappy@growcredit.com"
  }`,
};
export const CaptchaVerificationMessages = {
  CAPTCHA_VERIFICATION_ERROR_MESSAGE:
    "reCaptcha verification has been failed. Please try again",
};
export const BankAccountMessages = {
  CONFIRMATION_TITLE: "Don’t have a bank account?",
  CONFIRMATION_DESC:
    "If your bank isn’t listed, check back soon. We’re working to add more banks.",
  CONFIRMATION_BTN: "Yes, no bank account",
  CONFIRMATION_BTN_CANCEL: "Go back",
  TITLE_WHY_I_NEED_TO_CONNECT_BANK: "Why connect my bank account?",
  CLOSE_BUTTON_TEXT: "Close",
  MESSAGE_CONNECT_BANK: (
    <div>
      To determine the right plan for you, we use your banking information to
      evaluate your cash flow (minimum average balance or income).
      <br /> <p>Eligibility is subject to program underwriting criteria.</p>
      <p>
        Heads up: Alimony, child support, or separate maintenance income need
        not be revealed if you do not wish to have it considered as a basis for
        repaying this obligation.
      </p>
    </div>
  ),

  INFO_TEXT:
    "We use Plaid to connect your bank account. Millions of people trust Plaid with their sensitive financial data and it is used by top brands worldwide.",
  CONNECT_BANK: "Why do I need to connect my bank account?",
};
export const SelectSubscription = {
  SUBSCRIPTION_VALIDATION_MESSAGE: "Please select atleast one subscription",
};
// Demo Changes behappy@growcredit.com
export const MembershipAgreementMessages = {
  AUTO_PAYMENT_TITLE: " Continue without setting up your payment?",
  AUTO_PAYMENT_DESC:
    "Setting up an automatic payment is the easiest way to make sure you're making on time payments that may help improve your credit score.",
  AUTO_PAYMENT_BTN: "Continue with Autopay",
  PAYMENT_METHOD_BTN: "Add Payment Method",
  AUTO_PAYMENT_BTN_CANCEL: "Maybe later",
  ALMOST_DONE: "Almost done!",
  AGREEMENT_TITLE: "Confirm your loan terms",
};
export const ForgotPasswordMsgs = {
  PASSWORD_CHNAGE_SUUCCESS: "Your password has been changed successfully.",
};
