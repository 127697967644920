import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRoutes } from "react-router-dom";
import { showErrormessage } from "../../redux/slice/commonSlice";
import CErrorModal from "../../components/custom/CErrorModal";
import CssBaseline from "@mui/material/CssBaseline";
import defaultTheme, { GlobalStyled } from "../../theme";
import useDynamicTheme from "../../hooks/useDynamicTheme";
import { ThemeProvider } from "@mui/material/styles";
import { route_plaid_widget } from "../../routes";
import { useNavigate } from "react-router";
import {AppActions} from "../../redux/types";

const THEME_ACTIVE = {
  active: "light",
  light: {
    body: "#ffffff",
    button: "#6b35ee",
    buttonText: "#fdfafa",
    link: "#6b35ee",
    primary: "#2e2d2d",
  },
  dark: {
    body: "#ffffff",
    button: "#6b35ee",
    buttonText: "#fdfafa",
    link: "#6b35ee",
    primary: "#2e2d2d",
  },
};

const InvokePlaidHOC = () => {
  const navigate = useNavigate();
  const [allowed, setAllowed] = useState(false);
  const [theme, globalStyleConfig, setTheme] = useDynamicTheme(null);
  const content = useRoutes(route_plaid_widget);
  const dispatch = useDispatch();

  useEffect(() => {
    setTheme(THEME_ACTIVE);
  }, [setTheme]);

  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const partnerToken = parameters.get("ptoken");
    const userToken = parameters.get("utoken");

    if (userToken && partnerToken) {
      sessionStorage.setItem("user_token", userToken);
      sessionStorage.setItem("partner_token", partnerToken);
      setAllowed(true);
      dispatch({ type: AppActions.CUSTOMER_APPROVAL_STATUS });
    } else if (
      sessionStorage.getItem("user_token") &&
      sessionStorage.getItem("partner_token")
    ) {
      setAllowed(true);
      dispatch({ type: AppActions.CUSTOMER_APPROVAL_STATUS });
    } else {
      setAllowed(false);
      navigate("/invoke-plaid/begin");
      dispatch(
        showErrormessage("Valid Partner Token and User Token are required!")
      );
    }
  }, [dispatch, navigate]);

  return (
    <>
      <ThemeProvider theme={theme ? theme : defaultTheme}>
        {allowed && theme && (
          <>
            <CssBaseline />
            <GlobalStyled config={globalStyleConfig} />
            {content}
          </>
        )}
        <CErrorModal />
      </ThemeProvider>
    </>
  );
};

export default InvokePlaidHOC;
