
export const setSessionStorageItem = (key, value) => {
    if (value) {
        sessionStorage.setItem(key, value);
    }
}

export const addSessionStorageItemInObject = (objKey, sessionKey) => {
    return (sessionStorage.getItem(sessionKey) && {[objKey]: sessionStorage.getItem(sessionKey)})
}

export const setAndroidWidget = (key) => {
    const source = sessionStorage.getItem(key);
    if( source === "android-web" || source === "android"){
        return "android-widget";
    } else {
        return source || "web";
    }
}