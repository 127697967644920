import { createSlice } from "@reduxjs/toolkit";
import { CheckoutMessages } from "constants/Messages";

const initialState = {
  checkoutSuccess: false,
  checkoutSuccessMsg: "",
  checkoutError: false,
  checkoutErrorMsg: "",
  checkoutValidationMessages: {},
  declineError: false,
  addDebitCardSuccess: false,
  addDebitCardError: false,
  addDebitCardErrorMsg: "",
  addDebitCardValidationMessages: {},

  defaultPaymentError: false,
  defaultPaymentErrorMsg: "",
  defaultPaymentSuccess: false,
};

const checkout = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    checkoutViaAch: (state, action) => {
      let response = action.payload;
      if (response.error) {
        if (response.result) {
          state.checkoutError = true;
          state.checkoutValidationMessages = response.result.data
            ? response.result.data.reason
            : {};
          state.checkoutErrorMsg = CheckoutMessages.CHECKOUT_ERROR;
        } else {
          state.checkoutSuccess = true;
          state.checkoutErrorMsg = CheckoutMessages.CHECKOUT_ERROR;
        }
      } else {
        if (response.result.data.result) {
          state.checkoutSuccess = true;
          state.checkoutSuccessMsg = response.result.data.reason
            ? response.result.data.reason
            : CheckoutMessages.CHECKOUT_SUCCESS;
        } else {
          state.checkoutError = true;
          state.checkoutErrorMsg = response.result.data.reason;
        }
      }
      return state;
    },
    reset: (state, action) => {
      state.checkoutSuccess = false;
      state.checkoutSuccessMsg = "";
      state.checkoutError = false;
      state.checkoutErrorMsg = "";
      state.checkoutValidationMessages = {};
      state.declineError = false;

      return state;
    },
    resetDebitCardDetail: (state, action) => {
      state.addDebitCardSuccess = false;
      state.addDebitCardError = false;
      state.addDebitCardErrorMsg = "";
      return state;
    },
    checkoutViaCard: (state, action) => {
      let response = action.payload;
      if (response.error) {
        if (response.result) {
          state.checkoutError = true;
          state.checkoutValidationMessages = response.result.data
            ? response.result.data.reason
            : {};
          state.checkoutErrorMsg = CheckoutMessages.CHECKOUT_ERROR;
        } else {
          state.declineError = true;
        }
      } else {
        if (response.result.data.result) {
          state.checkoutSuccess = true;
          state.checkoutSuccessMsg = CheckoutMessages.CHECKOUT_SUCCESS;
        } else {
          state.checkoutError = true;
          state.checkoutErrorMsg = response.result.data.reason;
        }
      }
      return state;
    },
    addDebitCard: (state, action) => {
      let response = action.payload;
      if (response.error) {
        if (response.result) {
          state.addDebitCardError = true;
          state.addDebitCardValidationMessages = response.result.data
            ? response.result.data.reason
            : {};
          state.addDebitCardErrorMsg = CheckoutMessages.ADD_DEBIT_CARD_ERROR;
        }
      } else {
        if (response.result.data.result) {
          state.addDebitCardSuccess = true;
        } else {
          state.addDebitCardError = true;
          state.addDebitCardErrorMsg = response.result.data.reason;
        }
      }
      return state;
    },
    defaultPaymentMethod: (state, action) => {
      let response = action.payload;
      if (response.error) {
        if (!response?.result?.data?.result) {
          state.defaultPaymentError = true;
          state.defaultPaymentErrorMsg = response?.result?.data?.reason;
        } else {
          state.defaultPaymentError = true;
          state.defaultPaymentErrorMsg = CheckoutMessages.GENERAL_ERROR;
        }
      } else {
        if (response.result.data.result) {
          state.defaultPaymentSuccess = true;
        } else {
          state.defaultPaymentError = true;
          state.defaultPaymentErrorMsg = response?.result?.data?.reason;
        }
      }
      return state;
    },
    resetDefaultPayment: (state, action) => {
      state.defaultPaymentSuccess = false;
      state.defaultPaymentErrorMsg = "";
      state.defaultPaymentError = false;

      return state;
    },
  },
});
export const {
  reset,
  checkoutViaAch,
  checkoutViaCard,
  addDebitCard,
  resetDebitCardDetail,
  defaultPaymentMethod,
  resetDefaultPayment,
} = checkout.actions;
export default checkout.reducer;
