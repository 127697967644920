import React, { useState, useEffect } from "react";
import { createLightTheme, createDarkTheme } from "../theme";

const useDynamicTheme = function (config) {
  const [activeTheme, setActiveTheme] = useState(null);
  const [globalStyleConfig, setGlobalStyleConfig] = useState(null);

  const setTheme = React.useCallback((config) => {
    if (config) {
      const { active, light, dark } = config;
      if (active === "light") {
        setActiveTheme(createLightTheme(light));
        setGlobalStyleConfig(light);
      } else {
        setActiveTheme(createDarkTheme(dark));
        setGlobalStyleConfig(dark);
      }
    }
  }, []);

  useEffect(() => {
    setTheme(config);
  }, [config, setTheme]);

  return [activeTheme, globalStyleConfig, setTheme];
};

export default useDynamicTheme;
