import { Typography } from "@mui/material";
import {styled} from "@mui/material/styles";

const HeadingCTypography = styled(Typography)(({theme}) => ({
  color:theme.palette.text.primary,
}));

const CTypography = (props) => {
  const { children, variant, ...rest } = props;
  if(variant && variant === 'h1')
    return <HeadingCTypography variant ='h1' {...rest}>{children}</HeadingCTypography>;
  else
    return <Typography {...rest}>{children}</Typography>;
};

export default CTypography;
