import { configureStore } from "@reduxjs/toolkit";
import authentication from "redux/slice/authentication";
import commonReducer from "redux/slice/commonSlice";
import accountRegistration from "redux/slice/accountRegistration";
import checkout from "redux/slice/checkout";
import linkBankAccount from "redux/slice/linkBankAccount";
import membership from "redux/slice/membershipSlice";
import createSagaMiddleware from "@redux-saga/core";

import { rootSaga } from "./redux/sagas";
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    authentication,
    commonReducer,
    accountRegistration,
    checkout,
    linkBankAccount,
    membership
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      sagaMiddleware
    ),
});
sagaMiddleware.run(rootSaga);

export default store;
