import { Suspense, lazy } from "react";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/custom/AuthGuard";
import StatusCheck from "./components/custom/StatusCheck";

export const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Subscriptions = Loadable(
  lazy(() => import("./pages/SelectSubscription"))
);
const AccountRegistration = Loadable(
  lazy(() => import("./pages/AccountRegistration"))
);
const PersonalInformation = Loadable(lazy(() => import("./pages/About")));
const CreditGoals = Loadable(lazy(() => import("./pages/CreditGoals")));
const BankAccount = Loadable(lazy(() => import("./pages/BankAccount")));
const CurrentBalanace = Loadable(lazy(() => import("./pages/CurrentBalance")));
const MonthlyIncome = Loadable(lazy(() => import("./pages/MonthlyIncome")));
const Confirmation = Loadable(lazy(() => import("./pages/Confirmation")));
const IAgree = Loadable(lazy(() => import("./pages/IAgree")));
const Address = Loadable(lazy(() => import("./pages/Address")));
const DOB = Loadable(lazy(() => import("./pages/DateOfBirth")));
const PhoneNo = Loadable(lazy(() => import("./pages/PhoneNumber")));
const SSN = Loadable(lazy(() => import("./pages/SSN")));
const SecuredStatus = Loadable(lazy(() => import("./pages/SecuredStatus")));
const ChooseMembership = Loadable(
  lazy(() => import("./pages/ChooseMembership"))
);
const ConfirmPlan = Loadable(lazy(() => import("./pages/ConfirmPlan")));
const ConfirmCheckout = Loadable(lazy(() => import("./pages/ConfirmCheckout")));
const Checkout = Loadable(lazy(() => import("./pages/Checkout")));
const MembershipAgreement = Loadable(
  lazy(() => import("./pages/MembershipAgreement"))
);
const AgreementPending = Loadable(
  lazy(() => import("./pages/AgreementPending"))
);
const ReviewStatus = Loadable(lazy(() => import("./pages/ReviewStatus")));
const LinkBankAccount = Loadable(lazy(() => import("./pages/LinkBankAccount")));

const Review = Loadable(lazy(() => import("./pages/ReviewStatus/Review")));
const SecuredLoan = Loadable(
  lazy(() => import("./pages/ReviewStatus/SecuredUnderwriting"))
);
const Rejection = Loadable(
  lazy(() => import("./pages/ReviewStatus/Rejected/Rejection"))
);
const Congratulations = Loadable(lazy(() => import("./pages/Congratulations")));
const PendingPayment = Loadable(lazy(() => import("./pages/PendingPayment")));
const LegalAgreementMRVEFT = Loadable(
  lazy(() => import("./pages/LegalAgreement/MRV-EFT"))
);
const LegalAgreementBRBEFT = Loadable(
  lazy(() => import("./pages/LegalAgreement/BRB-EFT"))
);
const Login = Loadable(lazy(() => import("./pages/Login")));
const ForgotPassword = Loadable(lazy(() => import("./pages/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("./pages/ResetPassword")));

const InvokePlaid = Loadable(lazy(() => import("./widgets/InvokePlaid")));
const InvokePlaidBegin = Loadable(
  lazy(() => import("./widgets/InvokePlaid/InvokePlaidBegin"))
);
const InvokePlaidSuccess = Loadable(
  lazy(() => import("./widgets/InvokePlaid/InvokePlaidSuccess"))
);
const PaymentMethod = Loadable(lazy(() => import("./pages/PaymentMethod")));
const PaymentMethodBank = Loadable(
  lazy(() => import("./pages/PaymentMethodBank"))
);

const routes = [
  {
    path: "*",
    element: (
      <StatusCheck>
        <CreditGoals />
      </StatusCheck>
    ),
    children: [],
  },
  {
    path: "/subscriptions",
    element: <Subscriptions />,
    children: [],
  },
  {
    path: "/login",
    element: <Login />,
    children: [],
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    children: [],
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    children: [],
  },
  {
    path: "/signup",
    element: <AccountRegistration />,
    children: [],
  },

  {
    path: "/info",
    element: (
      <AuthGuard>
        <StatusCheck>
          <PersonalInformation />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/goals",
    element: <CreditGoals />,
    children: [],
  },
  {
    path: "/bankAccount",
    element: (
      <AuthGuard>
        <StatusCheck>
          <BankAccount />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/currentBalance",
    element: (
      <AuthGuard>
        <StatusCheck>
          <CurrentBalanace />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/monthlyIncome",
    element: (
      <AuthGuard>
        <StatusCheck>
          <MonthlyIncome />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },

  {
    path: "/linkBankAccount",
    element: (
      <AuthGuard>
        <StatusCheck>
          <LinkBankAccount />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/review",
    element: (
      <AuthGuard>
        <Review />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/securedLoan",
    element: (
      <AuthGuard>
        {/* <StatusCheck> */}
        <SecuredLoan />
        {/* </StatusCheck> */}
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/confirmation",
    element: (
      <AuthGuard>
        <StatusCheck>
          <Confirmation />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/IAgree",
    element: (
      <AuthGuard>
        {/* <StatusCheck> */}
        <IAgree />
        {/* </StatusCheck> */}
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/address",
    element: (
      <AuthGuard>
        {/* <StatusCheck> */}
        <Address />
        {/* </StatusCheck> */}
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/dob",
    element: (
      <AuthGuard>
        {/*<StatusCheck>*/}
        <DOB />
        {/*</StatusCheck>*/}
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/phone",
    element: (
      <AuthGuard>
        <StatusCheck>
          <PhoneNo />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/ssn",
    element: (
      <AuthGuard>
        <StatusCheck>
          <SSN />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },

  {
    path: "/securedStatus",
    element: (
      <AuthGuard>
        {/* <StatusCheck> */}
        <SecuredStatus />
        {/* </StatusCheck> */}
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/confirmPlan",
    element: (
      <AuthGuard>
        <StatusCheck>
          <ConfirmPlan />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/confirmCheckout",
    element: (
      <AuthGuard>
        {/* <StatusCheck> */}
        <ConfirmCheckout />
        {/* </StatusCheck> */}
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/status",
    element: (
      <AuthGuard>
        {/* <StatusCheck> */}
        <ReviewStatus />
        {/* </StatusCheck> */}
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/rejection",
    element: (
      <AuthGuard>
        <Rejection />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/membership",
    element: (
      <AuthGuard>
        {/* <StatusCheck> */}
        <ChooseMembership />
        {/* </StatusCheck> */}
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/checkout",
    element: (
      <AuthGuard>
        {/* <StatusCheck> */}
        <Checkout />
        {/* </StatusCheck> */}
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/agreement",
    element: (
      <AuthGuard>
        {/* <StatusCheck> */}
        <MembershipAgreement />
        {/* </StatusCheck> */}
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/agreement-pending",
    element: (
      <AuthGuard>
        <StatusCheck>
          <AgreementPending />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/agreement/mrv-eft",
    element: (
      <AuthGuard>
        <LegalAgreementMRVEFT />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/agreement/brb-eft",
    element: (
      <AuthGuard>
        <LegalAgreementBRBEFT />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/pending-payment",
    element: (
      <AuthGuard>
        <StatusCheck>
          <PendingPayment />
        </StatusCheck>
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/payment-method",
    element: (
      <AuthGuard>
        <PaymentMethod />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/payment-method-bank",
    element: (
      <AuthGuard>
        <PaymentMethodBank />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "/congratulations",
    element: (
      <StatusCheck>
        <Congratulations />
      </StatusCheck>
    ),
    children: [],
  },
];

export const route_plaid_widget = [
  {
    path: "/invoke-plaid",
    element: <InvokePlaid />,
    children: [
      {
        path: "/invoke-plaid/begin",
        element: <InvokePlaidBegin />,
      },
      {
        path: "/invoke-plaid/success",
        element: <InvokePlaidSuccess />,
      },
    ],
  },
];

export default routes;
