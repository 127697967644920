import axios from "axios";
import {addSessionStorageItemInObject, setAndroidWidget} from "../utils/sessionStorage";

const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;

export const sendApiRequest = async (obj = null, apiMethod, method) => {
  let config;
  let token;
  let partnerToken;
  if (window.sessionStorage.getItem("user_token")) {
    if (window.sessionStorage.getItem("user_token").charAt(0) === '"') {
      // Parse json in case if the user token is from web app
      token = JSON.parse(sessionStorage.getItem("user_token"));
    } else {
      //Return the token in case if the user token is from mobile app
      token = sessionStorage.getItem("user_token");
    }
  }
  if (window.sessionStorage.getItem("partner_token")) {
    partnerToken = window.sessionStorage.getItem("partner_token");
  }

  if(apiMethod === "onboarding/register") {
    if(sessionStorage.getItem('onboarding_app_version') && sessionStorage.getItem('onboarding_device_id')){
      obj.platform_details = {
        ...addSessionStorageItemInObject("name", "onboarding_name"),
        ...addSessionStorageItemInObject("version", "onboarding_version"),
        // ...addSessionStorageItemInObject("platform", "onboarding_platform"),
        ...addSessionStorageItemInObject("device_id", "onboarding_device_id"),
        ...addSessionStorageItemInObject("vendor_id", "onboarding_vendor_id"),
        ...addSessionStorageItemInObject("ip_address", "onboarding_ip_address"),
        ...addSessionStorageItemInObject("app_version", "onboarding_app_version"),
        ...addSessionStorageItemInObject("advertiser_id", "onboarding_advertiser_id"),
      }
      obj.platform_details = {
        ...obj.platform_details,
        platform: setAndroidWidget("onboarding_platform"),
      };

    } else {
      obj.platform_details = {
        version: window.navigator.appVersion,
        platform: window.navigator.userAgentData?.platform,
        userAgent: window.navigator.userAgent,
        is_mobile: window.navigator.userAgentData?.mobile,
      }
    }

  }
  else if(apiMethod === "onboarding/create") {
    obj.internal_client_name = setAndroidWidget("onboarding_source");
  }

  const data = obj === null ? null : JSON.stringify(obj);
  const returnOBJ = { error: false, result: {} };

  const END_POINT = `${BASE_URL_API}/v1/widget/api/request/`;
  const END_POINT_METHOD = "post";
  const END_POINT_PAYLOAD = {
    url: `/api/${apiMethod}`,
    method: method,
    payload: data ? data : null,
    token: token ? token : null,
  };

  config = {
    headers: {
      "Content-Type": "application/json",
      "X-Platform": "api",
      Authorization: partnerToken ? `Bearer ${partnerToken}` : "",
    },
    method: END_POINT_METHOD,
    url: END_POINT,
    data: END_POINT_PAYLOAD,
  };

  if (
    apiMethod === "widget/configuration" ||
    apiMethod.includes("subscription")
  ) {
    let url =
      apiMethod === "widget/configuration"
        ? `${BASE_URL_API}/v1/${apiMethod}/`
        : `${BASE_URL_API}/v1/${apiMethod}`;
    config = {
      method: method,
      url: url,
      headers: {
        "content-type": "application/json",
        // signature: "89711bf933af91d7ef268b4f5b574cd1f3e99cea56b362ac47e9964b466621b4",
        authorization: `Bearer ${partnerToken}`,
      },
      data: data ? data : {},
    };
  }

  await axios(config)
    .then(async (response) => {
      if (response.status === 200) {
        returnOBJ.error = false;
        returnOBJ.result = response.data;
      }
    })
    .catch(async (error) => {
      if (error.response && error.response.data) {
        returnOBJ.result = error.response.data;
        if (
          error.response.data.error &&
          error.response.data.error.code === "-1" &&
          error.response.data.error.message === "Unauthenticated."
        ) {
          window.sessionStorage.setItem("user_token", null);
          window.sessionStorage.clear();
          //window.location.href = '/';
        }
      }
      returnOBJ.error = true;
    });

  return returnOBJ;
};
