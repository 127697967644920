import React from "react";
import PropTypes from "prop-types";
import {useNavigate} from "react-router";

const AuthGuard = (props) => {
  const { children } = props;
  const navigate = useNavigate();

  if (!window.sessionStorage.getItem("user_token")) {
    navigate('/');
  } else {
    return <>{children}</>;
  }

  return <></>
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
