import { createSlice } from "@reduxjs/toolkit";
import { MembershipMessages } from "constants/Messages";

const membershipSlice = createSlice({
  name: "membership",
  initialState: {
    availablePlans: null,
    selectedPlan: null,
    loanAgreement: null,
    loanAgreementDOM: null,
    loanAccepted: null,
    esignAccepted: null,
    availablePlansError: null,
    selectedPlanError: null,
    loanAgreementError: null,
    loanAgreementDOMError: null,
    loanAcceptedError: null,
    esignAcceptedError: null
  },
  reducers: {
    offeredPlans: (state, action) => {
      if (!action.payload.error) {
        if(action.payload.result.data) {
          state.availablePlans = action.payload.result.data;
        }
      } else {
        state.availablePlansError = MembershipMessages.MEMBERSHIP_SCREEN_ERROR;
      }
      return state;
    },
    choosePlan: (state, action) => {
      if (!action.payload.error) {
        if (action.payload.result.data) {
          if (action.payload.result.data.result) {
            state.selectedPlan = true;
          } else {
            state.selectedPlanError = MembershipMessages.CHOOSE_MEMBERSHIP_ERROR;
          }
        }
      } else {
        state.selectedPlanError = MembershipMessages.CHOOSE_MEMBERSHIP_ERROR;
      }
      return state;
    },
    offeredLoan: (state, action) => {
      if (!action.payload.error) {
        if(action.payload.result.data) {
          state.loanAgreement = action.payload.result.data;
        }
      } else {
        state.loanAgreementError = MembershipMessages.AGREEMENT_SCREEN_ERROR;
      }
      return state;
    },
    offeredLoanDOM: (state, action) => {
      if (!action.payload.error) {
        if(action.payload.result.data) {
          state.loanAgreementDOM = action.payload.result.data;
        }
      } else {
        state.loanAgreementDOMError = MembershipMessages.AGREEMENT_SCREEN_ERROR;
      }
      return state;
    },
    acceptLoan: (state, action) => {
      if (!action.payload.error) {
        if(action.payload.result.data) {
          if (action.payload.result.data.result) {
            state.loanAccepted = true;
          } else {
            state.loanAcceptedError = MembershipMessages.ACCEPT_AGREEMENT_ERROR;
          }
        }
      } else {
        state.loanAcceptedError = MembershipMessages.ACCEPT_AGREEMENT_ERROR;
      }
      return state;
    },
    acceptEsign: (state, action) => {
      if (!action.payload.error) {
        if(action.payload.result.data) {
          if (action.payload.result.data.result) {
            state.esignAccepted = true;
          } else {
            state.esignAcceptedError = MembershipMessages.ACCEPT_ESIGN_AGREEMENT_ERROR;
          }
        }
      } else {
        state.esignAcceptedError = MembershipMessages.ACCEPT_ESIGN_AGREEMENT_ERROR;
      }
      return state;
    },
    resetMembership: (state, action) => {
      state.availablePlans = null;
      state.selectedPlan = null;
      state.loanAgreement = null;
      state.loanAgreementDOM = null;
      state.loanAccepted = null;
      state.esignAccepted = null;
      state.availablePlansError = null;
      state.selectedPlanError = null;
      state.loanAgreementError = null;
      state.loanAgreementDOMError = null;
      state.loanAcceptedError = null;
      state.esignAcceptedError = null
    },
  },
});

export const {
  offeredPlans,
  choosePlan,
  offeredLoan,
  offeredLoanDOM,
  acceptLoan,
  acceptEsign,
  resetMembership
} = membershipSlice.actions;

export default membershipSlice.reducer;
