import {
  createAccount,
  loginAccount,
  registerAccount,
  accountDetails,
  savePII,
  getSubscriptionListSaga,
} from "../slice/accountRegistration";
import { sendApiRequest } from "../../lib/axios";
import { AccountRegActions } from "../types";
import { put, takeEvery, call, takeLatest } from "redux-saga/effects";
// import { customerApprovalStatus } from "../slice/commonSlice";

export function* createAccountSaga(data) {
  const result = yield call(
    sendApiRequest,
    data.payload,
    "onboarding/create",
    "post"
  );
  yield put(createAccount(result));
}

export function* loginAccountSaga(data) {
  const result = yield call(
    sendApiRequest,
    data.payload,
    "login/access",
    "post"
  );
  yield put(loginAccount(result));
}

export function* personalInfoSaga(data) {
  const result = yield call(
    sendApiRequest,
    data.payload,
    "onboarding/register",
    "post"
  );
  yield put(registerAccount(result));
}
export function* savePIISaga(data) {
  const result = yield call(
    sendApiRequest,
    data.payload,
    "onboarding/save_pii",
    "post"
  );
  yield put(savePII(result));
}
export function* accountDetailsSaga() {
  const response = yield call(
    sendApiRequest,
    null,
    "post_register/account/details",
    "get"
  );
  yield put(accountDetails(response));
}
export function* getSubscriptionList() {
  const response = yield call(
    sendApiRequest,
    null,
    "subscription/list/?highrisk=0&premium=0&most_used=6",
    "get"
  );
  yield put(getSubscriptionListSaga(response));
}

export function* watchAccountRegAsync() {
  yield takeEvery(AccountRegActions.CREATE_ACCOUNT, createAccountSaga);
  yield takeEvery(AccountRegActions.LOGIN_ACCOUNT, loginAccountSaga);
  yield takeEvery(AccountRegActions.ADD_PERSONAL_INFO, personalInfoSaga);
  yield takeLatest(AccountRegActions.PERSONAL_ACCOUNT_INFO, accountDetailsSaga);
  yield takeEvery(AccountRegActions.SAVE_PII, savePIISaga);
  yield takeEvery(AccountRegActions.GET_SUBSCRIPTION_LIST, getSubscriptionList);
}
