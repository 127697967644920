import { put, takeEvery, call, takeLatest } from "redux-saga/effects";
import {
  logout,
  configure,
  forgotPasswordCode,
  restPassword,
} from "../slice/authentication";
import { sendApiRequest } from "lib/axios";
import { AuthActions } from "../types";

export function* logoutSaga() {
  const logoutUser = yield call(
    sendApiRequest,
    null,
    "login/access/revoke",
    "post"
  );
  yield put(logout(logoutUser));
}

export function* fetchConfigurationsSaga() {
  const configureUser = yield call(
    sendApiRequest,
    null,
    "widget/configuration",
    "get"
  );
  yield put(configure(configureUser));
}
export function* sendCode(data) {
  const codeRequest = yield call(
    sendApiRequest,
    data.payload,
    "password/code/send",
    "post"
  );
  yield put(forgotPasswordCode(codeRequest));
}
export function* resetPassword(data) {
  const codeRequest = yield call(
    sendApiRequest,
    data.payload,
    "password/code/confirm",
    "post"
  );
  yield put(restPassword(codeRequest));
}

export function* watchAuthAsync() {
  yield takeEvery(AuthActions.LOGOUT_ACTION, logoutSaga);
  yield takeLatest(AuthActions.FETCH_CONFIGURATIONS, fetchConfigurationsSaga);
  yield takeEvery(AuthActions.SEND_FORGOT_PASSWORD_CODE, sendCode);
  yield takeEvery(AuthActions.RESET_PASSWORD_REQUEST, resetPassword);
}
