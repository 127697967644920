export const AppActions = {
  REGISTRATION_CHECK: "REGISTRATION_CHECK",
  LANDING_PAGE_DATA: "LANDING_PAGE_CAPTURE_DATA",
  LANDING_PAGE_EMAIL: "LANDING_PAGE_SAVE_EMAIL",
  CUSTOMER_APPROVAL_STATUS: "CUSTOMER_APPROVAL_STATUS",
  TEXT_APPS_LINK: "TEXT_APPS_LINK",
  SEND_EVENT: "SEND_EVENT",
  LEGAL_AGREEMENT_FEES: "LEGAL_AGREEMENT_FEES",
  LEGAL_AGREEMENT_LOAN: "LEGAL_AGREEMENT_LOAN",
  LEGAL_AGREEMENT_ESIGN: "LEGAL_AGREEMENT_ESIGN",
  TEXT_LANDING_PAGE_APPS_LINK: "TEXT_LANDING_PAGE_APPS_LINK",
  LANDING_PAGE_PHONE_NO: "LANDING_PAGE_PHONE_NO",
  VERIFY_GOOGLE_CAPTCHA: "VERIFY_GOOGLE_CAPTCHA",
};

export const AuthActions = {
  LOGOUT_ACTION: "LOGOUT_ACTION",
  FETCH_CONFIGURATIONS: "FETCH_CONFIGURATIONS",
  SEND_FORGOT_PASSWORD_CODE: "SEND_FORGOT_PASSWORD_CODE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
};

export const AccountRegActions = {
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  LOGIN_ACCOUNT: "LOGIN_ACCOUNT",
  ADD_PERSONAL_INFO: "ADD_PERSONAL_INFO",
  PERSONAL_ACCOUNT_INFO: "PERSONAL_ACCOUNT_INFO",
  SAVE_PII: "SAVE_PII",
  GET_SUBSCRIPTION_LIST: "GET_SUBSCRIPTION_LIST",
};

export const Checkout = {
  CHECKOUT_VIA_ACH: "CHECKOUT_VIA_ACH",
  CHECKOUT_VIA_CARD: "CHECKOUT_VIA_CARD",
  CHECKOUT_DETAILS: "CHECKOUT_DETAILS",
  ADD_PAYMENT_METHOD: "ADD_PAYMENT_METHOD",
  SET_DEFAULT_PAYMENT_METHOD: "SET_DEFAULT_PAYMENT_METHOD",
};

export const LinkBankActions = {
  GET_PLAID_TOKEN: "GET_PLAID_TOKEN",
  GET_PLAID_RELINK_TOKEN: "GET_PLAID_RELINK_TOKEN",
  ADD_PLAID_BANK: "ADD_PLAID_BANK",
  GET_PLAID_BANK_FIX: "GET_PLAID_BANK_FIX",
  RETRY_ACH: "RETRY_ACH",
  LOG_BANK_METADATA: "LOG_BANK_METADATA",
  BANK_ACCOUNT_INFO: "BANK_ACCOUNT_INFO",
};

export const MembershipActions = {
  OFFERED_PLANS: "OFFERED_PLANS",
  CHOOSE_PLAN: "CHOOSE_PLAN",
  LOAN_AGREEMENT: "LOAN_AGREEMENT",
  LOAN_AGREEMENT_DOM: "LOAN_AGREEMENT_DOM",
  ACCEPT_LOAN_AGREEMENT: "ACCEPT_LOAN_AGREEMENT",
  ACCEPT_ESIGN_AGREEMENT: "ACCEPT_ESIGN_AGREEMENT",
};
export const CustomerGoalsActions = {
  CUSTOMER_GOALS: "CUSTOMER_GOALS",
};
