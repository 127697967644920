import React, { useEffect, useState } from "react";
import base64 from "base-64";
import CErrorModal from "components/custom/CErrorModal";
import { Container } from "@mui/material";
import { setLoading, showErrormessage } from "redux/slice/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { AccountRegActions, AuthActions } from "redux/types";
import { BoxContainer } from "./styles";
import Logo from "assets/GrowCreditIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { reset } from "../../redux/slice/accountRegistration";
import { setSessionStorageItem } from "../../utils/sessionStorage";
import RegistrationClosed from "./RegistationClosed";

const LogoComponent = () => {
  return (
    <BoxContainer>
      <img alt="logo" src={Logo} height={40} />
    </BoxContainer>
  );
};

export const AppHOC = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isConfigured, setFetchConfigurationSuccess] = useState(null);
  const [isregistrationAllowed, setRegistrationAllowed] = useState(null);
  const [login, setLogin] = useState(null);

  const { fetchConfiuration, fetchConfigErrorMessage, rules } = useSelector(
    (state) => state.authentication
  );

  const { loginSuccess, loginErrorMessage, loginError } = useSelector(
    (state) => state.accountRegistration
  );

  useEffect(() => {
    if (location.pathname === "/congratulations") return;

    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const uid = parameters.get("uid");
    const cc = parameters.get("cc");
    const partnerToken = parameters.get("pt");
    const flowDecision = parameters.get("fd");
    const frictionlessEligible = parameters.get("fe");
    const userToken = parameters.get("ut");
    const source = parameters.get("onboarding_source");
    const name = parameters.get("name");
    const version = parameters.get("version");
    const platform = parameters.get("platform");
    const device_id = parameters.get("device_id");
    const vendor_id = parameters.get("vendor_id");
    const ip_address = parameters.get("ip_address");
    const app_version = parameters.get("app_version");
    const advertiser_id = parameters.get("advertiser_id");

    setSessionStorageItem("onboarding_source", source);
    setSessionStorageItem("onboarding_name", name);
    setSessionStorageItem("onboarding_version", version);
    setSessionStorageItem("onboarding_platform", platform);
    setSessionStorageItem("onboarding_device_id", device_id);
    setSessionStorageItem("onboarding_vendor_id", vendor_id);
    setSessionStorageItem("onboarding_ip_address", ip_address);
    setSessionStorageItem("onboarding_app_version", app_version);
    setSessionStorageItem("onboarding_advertiser_id", advertiser_id);

    if (flowDecision) sessionStorage.setItem("flow_decision", flowDecision);
    if (frictionlessEligible)
      sessionStorage.setItem(
        "frictionless_eligible",
        frictionlessEligible
      );

    if (partnerToken && cc && !login) {
      sessionStorage.setItem("partner_token", partnerToken);
      sessionStorage.setItem("cc", cc);
      const decodedCC = base64.decode(cc);
      const decodedCC_arr = decodedCC.split("%");
      dispatch(setLoading(true));
      dispatch({
        type: AuthActions.FETCH_CONFIGURATIONS,
      });
      dispatch({
        type: AccountRegActions.LOGIN_ACCOUNT,
        payload: {
          username: decodedCC_arr[0],
          password: decodedCC_arr[1],
        },
      });
    } else if (partnerToken && uid) {
      sessionStorage.setItem("partner_token", partnerToken);
      sessionStorage.setItem("uid", uid);

      dispatch(setLoading(true));
      dispatch({
        type: AuthActions.FETCH_CONFIGURATIONS,
      });
    } else if (userToken && partnerToken && !login) {
      sessionStorage.setItem("user_token", atob(userToken));
      sessionStorage.setItem("partner_token", partnerToken);
      dispatch(setLoading(true));
      dispatch({
        type: AuthActions.FETCH_CONFIGURATIONS,
      });
    } else {
      if (
        !login &&
        sessionStorage.getItem("partner_token") &&
        sessionStorage.getItem("cc")
      ) {
        const cc = sessionStorage.getItem("cc");
        const decodedCC = base64.decode(cc);
        const decodedCC_arr = decodedCC.split("%");

        dispatch({
          type: AuthActions.FETCH_CONFIGURATIONS,
        });
        dispatch({
          type: AccountRegActions.LOGIN_ACCOUNT,
          payload: {
            username: decodedCC_arr[0],
            password: decodedCC_arr[1],
          },
        });
      } else if (
        !login &&
        sessionStorage.getItem("partner_token") &&
        sessionStorage.getItem("uid")
      ) {
        setFetchConfigurationSuccess(true);

        dispatch({
          type: AuthActions.FETCH_CONFIGURATIONS,
        });
      } else if (
        sessionStorage.getItem("user_token") &&
        sessionStorage.getItem("partner_token")
      ) {
        dispatch(setLoading(true));
        dispatch({
          type: AuthActions.FETCH_CONFIGURATIONS,
        });
      } else {
        if (!login) {
          dispatch(setLoading(false));
          dispatch(
            showErrormessage(
              "Partner token and UID/CC is required to load widget"
            )
          );
          dispatch(reset());
        }
      }
    }
  }, [navigate, dispatch, location.pathname, login]);

  useEffect(() => {
    if (loginError) {
      dispatch(setLoading(false));
      setFetchConfigurationSuccess(false);
      dispatch(showErrormessage(loginErrorMessage));
      dispatch(reset());
    }
  }, [loginError, dispatch, loginErrorMessage]);
  useEffect(() => {
    if (loginSuccess) {
      setFetchConfigurationSuccess(true);
      navigate("/info");
      dispatch(reset());
      dispatch(setLoading(false));
      setLogin(true);
    }
  }, [loginSuccess, dispatch, navigate]);

  useEffect(() => {
    if (fetchConfiuration) {
      dispatch(setLoading(false));
      if (rules && rules.registration_allowed !== null) {
        setRegistrationAllowed(rules.registration_allowed);
      }
      setFetchConfigurationSuccess(fetchConfiuration);
    } else if (fetchConfigErrorMessage !== "") {
      setFetchConfigurationSuccess(false);
      dispatch(showErrormessage(fetchConfigErrorMessage));
    }
  }, [fetchConfiuration, dispatch, fetchConfigErrorMessage, rules]);

  const getChildren = () => {
    if (isConfigured !== null) {
      if (isregistrationAllowed !== null && isregistrationAllowed === false) {
        return RegistrationClosed();
      }
      if (isConfigured === true) return children;
      if (isConfigured === false) return LogoComponent();
    }
  };
  return (
    <Container>
      {getChildren()}
      <CErrorModal />
    </Container>
  );
};
