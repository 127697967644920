import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, AccountRegActions } from "redux/types";
import { useNavigate } from "react-router";
import { setLoading, resetApprovalStatus } from "redux/slice/commonSlice";
import { resetAccountDetail } from "redux/slice/accountRegistration";

const StatusCheck = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [statusResult, setStatusResult] = useState(false);
  const [isBackPressed, setIsbackPressed] = useState(false);
  const { personalAccountDetails } = useSelector(
    (state) => state.accountRegistration
  );
  const {
    customerStatus,
    customerStatusError,
    // isPlaidError,
    goalBack,
    bankAccountBack,
    currentBalanceBack,
    monthlyIncomeBack,
    agreementback,
    dobback,
    phoneBack,
    ssnBack,
  } = useSelector((state) => state.commonReducer);

  if (requestedLocation && location.pathname !== requestedLocation) {
    if (window.sessionStorage.getItem("user_token")) {
      setRequestedLocation(location.pathname);
      setStatusResult(false);
      dispatch(setLoading(true));
      dispatch({ type: AppActions.CUSTOMER_APPROVAL_STATUS });
      dispatch({ type: AccountRegActions.PERSONAL_ACCOUNT_INFO });
    }
  }
  useEffect(() => {
    setIsbackPressed(
      goalBack ||
        bankAccountBack ||
        currentBalanceBack ||
        monthlyIncomeBack ||
        agreementback ||
        dobback ||
        phoneBack ||
        ssnBack
    );
    if (
      !(
        goalBack ||
        bankAccountBack ||
        currentBalanceBack ||
        monthlyIncomeBack ||
        agreementback ||
        dobback ||
        phoneBack ||
        ssnBack
      )
    )
      setStatusResult(true);
  }, [
    goalBack,
    bankAccountBack,
    currentBalanceBack,
    monthlyIncomeBack,
    agreementback,
    dobback,
    phoneBack,
    ssnBack,
  ]);

  useEffect(() => {
    if (location.pathname === "/congratulations") return;
    if (window.sessionStorage.getItem("user_token")) {
      setRequestedLocation(location.pathname);
      setStatusResult(false);
      dispatch(setLoading(true));
      dispatch({ type: AccountRegActions.PERSONAL_ACCOUNT_INFO });
      dispatch({ type: AppActions.CUSTOMER_APPROVAL_STATUS });
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (customerStatusError) {
      dispatch(setLoading(false));
      dispatch(resetApprovalStatus(null));
    }
  }, [customerStatusError, dispatch]);

  useEffect(() => {
    if (!isBackPressed) {
      if (!statusResult && customerStatus && personalAccountDetails) {
        dispatch(setLoading(false));
        //-----to do: revisit re-application scenario for following comment in proceeding if--------------//
        // customerStatus.account.reapplication_required === true ||

        if (
          customerStatus.account.reapplication_required === true ||
          !customerStatus.account.first_name
        ) {
          navigate("/info");
        } else {
          if (
            customerStatus.approval.rejected_at ||
            (customerStatus.approval.review_at &&
              !customerStatus.approval.approved_at)
          ) {
            navigate("/review");
          } else {
            if (customerStatus.account.is_secured === 1) {
              if (!customerStatus.account.has_plan) {
                navigate("/securedStatus");
              } else if (!customerStatus.approval.identity_pass) {
                if (!personalAccountDetails.address) {
                  navigate("/confirmation");
                } else if (!personalAccountDetails.birthday) {
                  navigate("/dob");
                } else if (!personalAccountDetails.phone_number) {
                  navigate("/phone");
                } else if (!personalAccountDetails.ssn) {
                  navigate("/ssn");
                }
              } else if (!customerStatus.loan.is_loan_queued) {
                navigate("/confirmPlan");
              } else if (
                !customerStatus.account.upfront_collection_status &&
                customerStatus.account.current_plan !== "build_free"
              ) {
                navigate("/confirmCheckout");
              } else if (customerStatus.account.completed_at) {
                navigate("/congratulations");
              } else if (
                customerStatus.account.upfront_collection_status === "failed"
              ) {
                navigate("/confirmCheckout");
              } else if (
                customerStatus.account.upfront_collection_status === "pending"
              ) {
                if (customerStatus.account.completed_at) {
                  if (customerStatus.account.is_upfront_retry === true) {
                    navigate("/pending-payment");
                  } else {
                    navigate("/congratulations");
                  }
                } else {
                  navigate("/pending-payment");
                }
              } else if (
                customerStatus.account.upfront_collection_status === "processed"
              ) {
                if (customerStatus.account.completed_at) {
                  // if (customerStatus.account.is_upfront_retry === true) {
                  //   window.location.href =
                  //     BASE_URL +
                  //     "dashboard/subscriptions?onboard=" +
                  //     window.btoa(window.sessionStorage.getItem("user_token"));
                  // } else {
                  navigate("/congratulations");
                  // }
                } else {
                  navigate("/pending-payment");
                }
              } else {
                navigate("/pending-payment");
              }
            } else if (
              !customerStatus.approval.has_bank_account &&
              customerStatus.account.bank_required
            ) {
              navigate("/linkBankAccount");
            }
            // else if (!customerStatus.approval.has_balance) {
            //   navigate("/currentBalance");
            // } else if (!customerStatus.approval.has_monthly_income) {
            //   navigate("/monthlyIncome");
            // }
            else if (
              !customerStatus.account.has_bank_verified &&
              customerStatus.account.bank_required
            ) {
              navigate("/linkBankAccount");
            }
            // else if (customerStatus.approval.approved_at) {
            else if (
              !customerStatus.account.has_plan &&
              !customerStatus.loan.accepted_at
            ) {
              // navigate("/review");
              navigate("/membership");
            } else if (customerStatus.loan.is_loan_queued) {
              if (!customerStatus.loan.accepted_at) {
                navigate("/agreement-pending");
              } else {
                if (customerStatus.account.current_plan !== "build_free") {
                  if (!customerStatus.account.upfront_collection_status) {
                    navigate("/confirmCheckout");
                  } else if (
                    customerStatus.account.upfront_collection_status ===
                    "failed"
                  ) {
                    navigate("/confirmCheckout");
                  } else if (
                    customerStatus.account.upfront_collection_status ===
                    "pending"
                  ) {
                    if (customerStatus.account.completed_at) {
                      if (customerStatus.account.is_upfront_retry === true) {
                        navigate("/pending-payment");
                      } else {
                        navigate("/congratulations");
                      }
                    } else {
                      navigate("/pending-payment");
                    }
                  } else if (
                    customerStatus.account.upfront_collection_status ===
                    "processed"
                  ) {
                    if (customerStatus.account.completed_at) {
                      // if (customerStatus.account.is_upfront_retry === true) {
                      //   window.location.href =
                      //     BASE_URL +
                      //     "dashboard/subscriptions?onboard=" +
                      //     window.btoa(
                      //       window.sessionStorage.getItem("user_token")
                      //     );
                      // } else {
                      navigate("/congratulations");
                      // }
                    } else {
                      navigate("/pending-payment");
                    }
                  }
                } else {
                  navigate("/congratulations");
                }
              }
            } else if (!customerStatus.approval.identity_pass) {
              if (!personalAccountDetails.address) {
                navigate("/confirmation");
              } else if (!personalAccountDetails.birthday) {
                navigate("/dob");
              } else if (!personalAccountDetails.phone_number) {
                navigate("/phone");
              } else if (!personalAccountDetails.ssn) {
                navigate("/ssn");
              }
              //  else {
              //   navigate("/confirmPlan");
              // }
            } else if (!customerStatus.account.is_esign_accepted) {
              navigate("/confirmPlan");
            } else {
              navigate("/review");
            }
          }
        }
        dispatch(resetAccountDetail());
        dispatch(resetApprovalStatus(null));
        setStatusResult(true);
      }
    } else {
      dispatch(setLoading(false));
    }
  }, [
    customerStatus,
    personalAccountDetails,
    dispatch,
    isBackPressed,
    navigate,
    statusResult,
  ]);

  if (window.sessionStorage.getItem("user_token")) {
    if (isBackPressed) return <>{children}</>;
    return statusResult && <>{children}</>;
  }
  return <>{children}</>;
};

StatusCheck.propTypes = {
  children: PropTypes.node,
};

export default StatusCheck;
