import React, {useState, useCallback, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router";
import {datadogRum} from "@datadog/browser-rum";
import {datadogLogs} from "@datadog/browser-logs";
import {sendDDLog} from "../../lib/datadogService";
import {resetPlaidTokenError} from "../../redux/slice/linkBankAccount";
import CPlaidErrorModal from "../../components/custom/CPlaidErrorModal";

const InvokePlaid = () => {
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const dispatch = useDispatch();
  const { plaidTokenError, plaidTokenErrorMsg } = useSelector(
    (state) => state.linkBankAccount
  );

  const { customerStatus } = useSelector((state) => state.commonReducer);

  const handlePlaidErrorClose = useCallback(() => {
    setOpenErrorModal(false);
  }, []);

  const handlePlaidErrorReset = useCallback(() => {
    dispatch(resetPlaidTokenError());
    window.location.reload();
  }, [dispatch]);

  useEffect(() => {
    if (
        process.env.REACT_APP_ENV === "production" ||
        process.env.REACT_APP_ENV === "sandbox"
    ) {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: "datadoghq.com",
        service: process.env.REACT_APP_DATADOG_RUM_APP_NAME,
        version: process.env.REACT_APP_VESION,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
        silentMultipleInit: true,
        forwardConsoleLogs: false,
      });
      datadogRum.setGlobalContextProperty("UserInfo", {
        CustomerEmail: customerStatus && customerStatus.account.email,
        CustomerId: customerStatus && customerStatus.account.customer_guid,
      });
      datadogRum.setUserProperty(
          "userEmail",
          customerStatus && customerStatus.account.email
      );
      datadogRum.startSessionReplayRecording();
    }
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
      forwardErrorsToLogs: false,
      sessionSampleRate: 100,
      silentMultipleInit: true,
    });
    datadogLogs.setUser({
      customerId: customerStatus && customerStatus.account.customer_id,
      email: customerStatus && customerStatus.account.email,
    });
    datadogLogs.setUserProperty("type", "customer");
  }, [customerStatus, dispatch]);

  useEffect(() => {
    if (plaidTokenError && plaidTokenErrorMsg !=="") {
      setOpenErrorModal(true);
      sendDDLog(
          "Get Plaid Token Failure",
          {
            customerId: customerStatus && customerStatus.account.customer_id,
            ScreenName: "/linkBankAccount",
          },
          "info"
      );
    }
  }, [customerStatus, plaidTokenError, plaidTokenErrorMsg]);

  return (<>
    {!plaidTokenError && <Outlet />}
    {openErrorModal &&
      <CPlaidErrorModal
          open={openErrorModal}
          content={plaidTokenErrorMsg}
          handleClose={handlePlaidErrorClose}
          handleReset={handlePlaidErrorReset}
      />}
  </>);
};

export default InvokePlaid;
