import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";

export const BRANDING_DEFAULT = {
  icons: {
    gcLogoUrl: "",
    clientLogoUrl: "",
    gcLogoDisplay: false,
    clientLogoDisplay: false,
  },
  theme: {
    dark: {
      body: "#22253f",
      button: "#7077f3",
      buttonText: "#24275f",
      primary: "#fcfcfc",
      link: "#7077f3",
      input: "#ffffff",
    },
    light: {
      body: "#ffffff",
      button: "#a80888",
      primary: "#2e2d2d",
      buttonText: "#fdfafa",
      link: "#a80888",
      input: "#182142",
    },
    active: "light",
  },
};
export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
};

export const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

export const GlobalStyled = ({ config }) => {
  return (
    <GlobalStyles
      styles={{
        "::selection": {
          color: config.buttonText,
          backgroundColor: config.button,
        },
        body: {
          color: hexToRGB(config.primary, "0.87"),
          backgroundColor: config.body,
        },
      }}
    />
  );
};

export const createLightTheme = (config) => {
  return responsiveFontSizes(
    createTheme({
      spacing: 4,
      shape: {
        borderRadius: 6,
      },
      typography: {
        fontFamily: ["Lato", "Roboto", "Raleway", "Open Sans"].join(","),
        h1: {
          fontSize: "34px",
          lineHeight: 1.4,
          fontWeight: 700,
          letterSpacing: "-0.34px",
        },
      },
      palette: {
        mode: "light",
        // background: {
        //   default: "#009900",
        //     paper:"#ff0000"
        // },
        primary: {
          main: config.primary,
          light: hexToRGB(config.primary, "0.2"),
          dark: newShade(config.primary, -50),
          contrastText: config.buttonText,
        },
        secondary: {
          main: config.button,
          light: newShade(config.button, 50),
          dark: newShade(config.button, -50),
        },
        button: {
          main: config.button,
          light: hexToRGB(config.button, "0.2"),
          text: config.buttonText,
        },
        link: {
          main: config.link,
        },
        background: {
          default: "#e61818",
          // paper: hexToRGB(config.button, "0.6"),
        },
        text: {
          primary: config.primary,
          secondary: config.primary,
          light: config.input ? hexToRGB(config.input, "0.06") : hexToRGB("#6B35EE", "0.06"),
        },
      },
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              background: config.input ? hexToRGB(config.input, 0.06) : hexToRGB("#6B35EE", 0.06),
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === "contained" &&
                ownerState.color === "primary" && {
                  backgroundColor: config.button,
                  color: config.buttonText,
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: newShade(config.button, -10),
                  },
                }),
            }),
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === "h1" && {
                color: `${config.primary}`,
              }),
            }),
          },
        },
      },
    })
  );
};
export const createDarkTheme = (config) => {
  return responsiveFontSizes(
    createTheme({
      spacing: 4,
      shape: {
        borderRadius: 6,
      },

      typography: {
        fontFamily: ["Lato", "Roboto", "Raleway", "Open Sans"].join(","),
        h1: {
          fontSize: "34px",
          lineHeight: 1.4,
          fontWeight: 700,
          letterSpacing: "-0.34px",
        },
      },
      palette: {
        mode: "dark",
        // background: {
        //   default: "red",
        //   paper: "red",
        // },
        primary: {
          main: config.primary,
          light: hexToRGB(config.primary, "0.2"),
          dark: newShade(config.primary, -50),
          contrastText: config.buttonText,
        },
        secondary: {
          main: config.button,
          light: newShade(config.button, 50),
          dark: newShade(config.button, -50),
        },
        button: {
          main: config.button,
          light: hexToRGB(config.button, "0.3"),
          text: config.buttonText,
        },
        link: {
          main: config.link,
        },
        background: {
          default: "#e61818",
          paper: config.body,
        },
        text: {
          primary: config.primary,
          secondary: config.primary,
          light: config.input ? hexToRGB(config.input, "0.25") : hexToRGB("#ffffff", "0.25"),
        },
      },
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              background: config.input ? hexToRGB(config.input, 0.06) : hexToRGB("#ffffff", 0.06),
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === "contained" &&
                ownerState.color === "primary" && {
                  backgroundColor: config.button,
                  color: config.buttonText,
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: newShade(config.button, -10),
                  },
                }),
            }),
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === "h1" && {
                color: `${config.primary}`,
              }),
            }),
          },
        },
      },
    })
  );
};

const defaultTheme = responsiveFontSizes(
  createTheme({
    spacing: 4,
    shape: {
      borderRadius: 6,
    },
    typography: {
      fontFamily: ["Lato", "Roboto", "Raleway", "Open Sans"].join(","),
    },
    palette: {
      background: {
        default: "#009900",
      },
      primary: {
        main: "#6B35EE",
      },
      secondary: {
        main: "#6B35EE",
      },
      text: {
        primary: "#182142",
        secondary: "#28335B",
        light: "rgba(24, 33, 66, 0.06)",
      },
    },
  })
);

export default defaultTheme;
