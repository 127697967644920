import { createSlice } from "@reduxjs/toolkit";
import {
  ConfigurationMessages,
  GlobalMessages,
  ForgotPasswordMsgs,
} from "constants/Messages";
import { BRANDING_DEFAULT } from "../../theme";

const initialState = {
  isValidUser: "",
  configurations: null,
  fetchConfiuration: false,
  fetchConfigErrorMessage: "",
  rules: null,
  codeRecievedSuccess: false,
  codeRecievedError: false,
  codeRecievedErrorMessage: "",
  resetPasswordSuccess: false,
  resetPasswordSuccessMessage: "",
  resetPasswordError: false,
  resetPasswordErrorMessage: "",
};

const authentication = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isValidUser = !action.payload.error;
      return state;
    },
    logout: (state) => {
      state.isValidUser = false;
      return state;
    },
    forgotPasswordCode: (state, action) => {
      let error = action.payload.error;
      let result = action.payload.result;

      if (!error) {
        state.codeRecievedSuccess = true;
      } else {
        state.codeRecievedError = true;
        state.codeRecievedErrorMessage = result.message
          ? result.message
          : GlobalMessages.SERVER_ERROR;
      }
      return state;
    },
    restPassword: (state, action) => {
      let error = action.payload.error;
      let result = action.payload.result;
      if (!error) {
        state.resetPasswordSuccess = true;
        state.resetPasswordSuccessMessage = result.data.reason
          ? result.data.reason
          : ForgotPasswordMsgs.PASSWORD_CHNAGE_SUUCCESS;
      } else {
        state.resetPasswordError = true;
        state.resetPasswordErrorMessage = result.message
          ? result.message
          : result.error
          ? result.error.message
          : GlobalMessages.SERVER_ERROR;
      }
      return state;
    },
    reset: (state) => {
      state.codeRecievedSuccess = false;
      state.codeRecievedError = false;
      state.codeRecievedErrorMessage = "";
      state.resetPasswordSuccess = false;
      state.resetPasswordSuccessMessage = "";
      state.resetPasswordError = false;
      state.resetPasswordErrorMessage = "";
      return state;
    },
    configure: (state, action) => {
      let response = action.payload;
      if (!response.error) {
        state.fetchConfiuration = true;
        if (response.result.branding === null) {
          response.result.branding = BRANDING_DEFAULT;
        }
        state.configurations = response.result;
        state.rules = response.result.rules;
        // TEMP CODE TO VERIFY NON_SECURE FLOW
        //   state.rules = {
        //     ...state.rules,
        //   // bank_required:true
        //   //   payment_types: null,
        //     payment_types: ["debit", "ach", "paypal"],
        // }
        // TEMP CODE ENDS

        let clientName =
          response.result?.configuration?.clientName ?? "Grow Credit";

        let appRedirectUrl =
          response.result?.configuration?.redirectUrl ?? null;

        sessionStorage.setItem("appRedirectUrl", appRedirectUrl);
        sessionStorage.setItem("clientName", clientName);
        let clientId = response.result?.configuration?.clientId ?? "";
        sessionStorage.setItem("clientId", clientId);
        // state.configurations = response;
      } else {
        state.fetchConfiuration = false;
        state.fetchConfigErrorMessage =
          ConfigurationMessages.FETCH_CONFIGURATION_ERROR_MESSAGE;
        state.configurations = null;
      }

      return state;
    },
  },
});

export const {
  login,
  logout,
  configure,
  forgotPasswordCode,
  restPassword,
  reset,
} = authentication.actions;

export default authentication.reducer;
