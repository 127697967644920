export const MembershipColors = {
  disabledTextColor: "#9b9b9b",
  enabledTextColor: "#727A9A",
  darkTextColor: "#182142",
  buildColor: "#4D5679",
  gradientBuild: "linear-gradient(152deg, #A1A6B3 0%, #8B909A 100%)",
  gradientAccelerate:
    "linear-gradient(90deg, #BC2DFF 0%, #6B35EE 10%, #BC2DFF 100%)",
  gradientGrow: "linear-gradient(90deg, #0B67D3 0%, #11B8AE 10%, #0B67D3 100%)",
};

export const ToastOptions = {
  error: {
    style: {
      background: "#FFCCCC",
    },
  },
  success: {
    style: {
      background: "#98fb98",
    },
  },
};

export const getThemeConfig = (themeApi) => {
  return themeApi.branding.theme;
};

export const getActiveThemeConfig = (themeApi) => {
  let ActiveTheme;
  themeApi?.branding?.theme?.active === "dark"
    ? (ActiveTheme = themeApi?.branding?.theme?.dark)
    : (ActiveTheme = themeApi?.branding?.theme?.light);
  return ActiveTheme;
};

export const getActiveButtonColor = (themeApi, key) => {
  let ActiveTheme;
  themeApi?.branding?.theme?.active === "dark"
    ? (ActiveTheme = themeApi?.branding?.theme?.dark)
    : (ActiveTheme = themeApi?.branding?.theme?.light);
  return ActiveTheme[key];
};
