import { call, put, takeEvery } from "redux-saga/effects";
import { sendApiRequest } from "../../lib/axios";
import { LinkBankActions } from "../types";
import {
  getPlaidToken,
  addBank,
  getPlaidUpdateToken,
} from "../slice/linkBankAccount";

export function* getPlaidTokenSaga() {
  const result = yield call(
    sendApiRequest,
    null,
    "post_register/bank/token",
    "get"
  );
  yield put(getPlaidToken(result));
}
export function* addBankSaga(data) {
  const result = yield call(
    sendApiRequest,
    data.payload,
    "post_register/bank/add",
    "post"
  );
  yield put(addBank(result));
}
export function* getPlaidRelinkToken() {
  const result = yield call(
    sendApiRequest,
    null,
    "bank/link/update/token",
    "get"
  );
  yield put(getPlaidUpdateToken(result));
}
export function* fixBankSaga(data) {
  yield call(
    sendApiRequest,
    data.payload,
    "bank/link/fix_item_error",
    "post"
  );
}
export function* retryACHBankSaga() {
  yield call(
    sendApiRequest,
    null,
    "post_register/bank/retry_ach",
    "post"
  );
}
export function* logBankMetadataSaga(data) {
  yield call(
    sendApiRequest,
    data.payload,
    "post_register/bank/meta/log",
    "post"
  );
}

export function* watchLinkBankAsync() {
  yield takeEvery(LinkBankActions.GET_PLAID_TOKEN, getPlaidTokenSaga);
  yield takeEvery(LinkBankActions.GET_PLAID_RELINK_TOKEN, getPlaidRelinkToken);
  yield takeEvery(LinkBankActions.ADD_PLAID_BANK, addBankSaga);
  yield takeEvery(LinkBankActions.GET_PLAID_BANK_FIX, fixBankSaga);
  yield takeEvery(LinkBankActions.RETRY_ACH, retryACHBankSaga);
  yield takeEvery(LinkBankActions.LOG_BANK_METADATA, logBankMetadataSaga);
}
