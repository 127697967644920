import React from "react";
import { AuthProvider } from "./contexts/AuthTokenContext";
import { AppProvider } from "./contexts/AppContext";
import App from "./App";
import { AppHOC } from "./hoc/AppHOC";
import InvokePlaidHOC from "./hoc/InvokePlaidHOC";
import { useLocation } from "react-router-dom";
import InvokePlaid from "./widgets/InvokePlaid";

const AppSelector = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname.startsWith("/invoke-plaid") ? (
        <InvokePlaidHOC>
          <InvokePlaid />
        </InvokePlaidHOC>
      ) : (
        // <--- we need to uncomment it for landing page data capture when we will enbale grow credit onboarding  in widget------->
        <AppProvider>
          <AuthProvider>
            <AppHOC>
              <App />
            </AppHOC>
          </AuthProvider>
        </AppProvider>
      )}
    </>
  );
};

export default AppSelector;
